import { Routes } from '@angular/router';
import { OrganizationGuard } from '../modules/auth/services/organization.guard';
import { RoutePath } from '../_metronic/kt/_utils/routing.enum';

const Routing: Routes = [
  {
    path: RoutePath.RoutePath_88,
    loadChildren: () => 
      import('../modules/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: RoutePath.RoutePath_89,
    // canActivate: [OrganizationGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: RoutePath.RoutePath_90,
    loadChildren: () =>
      import('../modules/organization/organization.module').then((m) => m.OrganizationModule),
  },
  {
    path: RoutePath.RoutePath_161,
    loadChildren: () =>
      import('../modules/sales-workflow/sales-workflow.module').then((m) => m.SalesWorkflowModule),
  },
  {
    path: RoutePath.RoutePath_91,
    loadChildren: () =>
      import('../modules/website-builder/website-builder.module').then((m) => m.WebsiteBuilderModule),
  },
  {
    path: RoutePath.RoutePath_130,
    loadChildren: () =>
      import('../modules/production-management/production-management.module').then((m) => m.ProductionManagementModule),
  },
  {
    path: RoutePath.RoutePath_92,
    // canActivate: [OrganizationGuard],
    loadChildren: () =>
      import('../modules/hiring/hiring.module').then((m) => m.HiringModule),
  },
  {
    path: RoutePath.RoutePath_140,
    loadChildren: () =>
      import('../modules/purchase/purchase.module').then((m) => m.PurchaseModule),
  },
  {
    path: RoutePath.RoutePath_93,
    loadChildren: () =>
      import('../modules/sales/sales.module').then((m) => m.SalesModule),
  },
  {
    path: RoutePath.RoutePath_104,
    canActivate: [OrganizationGuard],
    loadChildren: () =>
      import('../modules/attendance/attendance.module').then((m) => m.AttendanceModule),
  },
  {
    path: RoutePath.RoutePath_94,
    canActivate: [OrganizationGuard],
    loadChildren: () =>
      import('../modules/organization/accessibility/accessibility.module').then((m) => m.AccessibilityModule),
  },
  {
    path: RoutePath.RoutePath_134,
    canActivate: [OrganizationGuard],
    loadChildren: () =>
      import('../modules/appointment/appointment.module').then((m) => m.AppointmentModule),
  },
  {
    path: RoutePath.RoutePath_95,
    loadChildren: () =>
      import('../modules/report/report.module').then((m) => m.ReportModule),
  },
  {
    path: RoutePath.RoutePath_96,
    loadChildren: () =>
      import('../modules/subscription/subscription.module').then((m) => m.SubscriptionModule),
  },
  {
    path: RoutePath.RoutePath_97,
    loadChildren: () =>
      import('../modules/request/request.module').then((m) => m.RequestModule),
  },
  {
    path: RoutePath.RoutePath_98,
    loadChildren: () =>
      import('../modules/suggestions/suggestions.module').then((m) => m.SuggestionsModule),
  },
  {
    path: RoutePath.RoutePath_100,
    loadChildren: () =>
      import('../modules/crm/crm.module').then((m) => m.CrmModule),
  },
  {
    path: RoutePath.RoutePath_101,
    loadChildren: () =>
      import('../modules/project-management/project-management.module').then((m) => m.ProjectManagementModule),
  },
  {
    path: RoutePath.RoutePath_102,
    loadChildren: () =>
      import('../modules/social/social.module').then((m) => m.SocialModule),
  },
  {
    path: RoutePath.RoutePath_34,
    canActivate: [OrganizationGuard],
    loadChildren: () =>
      import('../modules/salary/salary.module').then((m) => m.SalaryModule),
  },
  {
    path: RoutePath.RoutePath_103,
    canActivate: [OrganizationGuard],
    loadChildren: () =>
      import('./builder/builder.module').then((m) => m.BuilderModule),
  },
  {
    path: RoutePath.RoutePath_126,
    loadChildren:()=> import('../modules/integration/integration.module').then((m)=>m.IntegrationModule)
  },
  {
    path: RoutePath.RoutePath_120,
    loadChildren: () =>
      import('../../app/modules/organization/assets-management/assets-management.module').then((m) => m.AssetsManagementModule)
  },
  {
    path: RoutePath.RoutePath_166,
    loadChildren: () =>
      import('../../app/modules/set-up-config/set-up-config.module').then((m) => m.SetUpConfigModule)
  },
  {
    path: RoutePath.RoutePath_170,
    loadChildren: () =>
      import('../modules/expense/expense.module').then((m) => m.ExpenseModule),
  },
  {
    path: RoutePath.Root,
    redirectTo: `/${RoutePath.RoutePath_88}`,
    pathMatch: RoutePath.Full,
  },
  {
    path: RoutePath.Wildcard,
    redirectTo: `${RoutePath.RoutePath_2}/${RoutePath.RoutePath_3}`,
  },
];

export { Routing };
