<div class="card-body d-flex flex-center flex-column p-9">
  <div class="mb-5">
    <div class="symbol symbol-75px symbol-circle">
      <ng-container *ngIf="color">
        <span
          class="symbol-label fs-5 fw-bolder"
          [ngClass]="'bg-light-' + color"
          [ngClass]="'text-' + color"
        >
          {{ name.charAt(0) }}
        </span>
      </ng-container>
      <ng-container *ngIf="!color && avatar">
        <img alt="Pic" [src]="avatar" />
      </ng-container>
      <ng-container *ngIf="online">
        <div
          class="
            symbol-badge
            bg-success
            start-100
            top-100
            border-4
            h-15px
            w-15px
            ms-n3
            mt-n3
          "
        ></div>
      </ng-container>
    </div>
  </div>

  <a
    class="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0 cursor-pointer"
  >
    {{ name }}
  </a>

  <div class="fw-bold text-gray-400 mb-6">{{ job }}</div>

  <div class="d-flex flex-center flex-wrap mb-5">
    <div
      class="
        border border-gray-300 border-dashed
        rounded
        min-w-125px
        py-3
        px-4
        mx-3
        mb-3
      "
    >
      <div class="fs-6 fw-bolder text-gray-700">{{ avgEarnings }}</div>
      <div class="fw-bold text-gray-400">Avg. Earnings</div>
    </div>

    <div
      class="
        border border-gray-300 border-dashed
        rounded
        min-w-125px
        py-3
        mx-3
        px-4
        mb-3
      "
    >
      <div class="fs-6 fw-bolder text-gray-700">{{ totalEarnings }}</div>
      <div class="fw-bold text-gray-400">Total Sales</div>
    </div>
  </div>

  <a class="btn btn-sm btn-light cursor-pointer">
    <span
      [inlineSVG]="'./assets/media/icons/duotune/arrows/arr075.svg'"
      class="svg-icon svg-icon-3"
    ></span>
    Connect
  </a>
</div>
