<!-- begin::Beader -->
<div class="card-header border-0 py-5">
  <h4 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1">Trends</span>

    <span class="text-muted fw-bold fs-7">Latest trends</span>
  </h4> 

  <div class="card-toolbar">
    <!-- begin::Menu -->
    <button
      type="button"
      class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <span
        [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </button>
    <app-dropdown-menu1></app-dropdown-menu1>
    <!-- end::Menu -->
  </div>
</div>
<!-- end::Header -->

<!-- begin::Body -->
<div class="card-body d-flex flex-column">
  <!-- begin::Chart -->
  <div
    #chartRef
    class="mixed-widget-5-chart card-rounded-top"
    [style.height]="chartHeight"
  >
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [dataLabels]="chartOptions.dataLabels"
      [stroke]="chartOptions.stroke"
      [legend]="chartOptions.legend"
      [fill]="chartOptions.fill"
      [states]="chartOptions.states"
      [tooltip]="chartOptions.tooltip"
      [colors]="chartOptions.colors"
      [markers]="chartOptions.markers"
      [plotOptions]="chartOptions.plotOptions"
    ></apx-chart>
  </div>
  <!-- end::Chart -->

  <!-- begin::Items -->
  <div class="mt-5">
    <!-- begin::Item -->
    <div class="d-flex flex-stack mb-5">
      <!-- begin::Section -->
      <div class="d-flex align-items-center me-2">
        <!-- begin::Symbol -->
        <div class="symbol symbol-35px me-3">
          <div class="symbol-label bg-light">
            <img
              src="./assets/media/svg/brand-logos/plurk.svg"
              alt=""
              class="h-50"
            />
          </div>
        </div>
        <!-- end::Symbol -->

        <!-- begin::Title -->
        <div>
          <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bolder">
            Top Authors
          </a>
          <div class="fs-7 text-muted fw-bold mt-1">
            Ricky Hunt, Sandra Trepp
          </div>
        </div>
        <!-- end::Title -->
      </div>
      <!-- end::Section -->

      <!-- begin::Label -->
      <div class="badge badge-light fw-bold py-4 px-3">+82$</div>
      <!-- end::Label -->
    </div>
    <!-- end::Item -->

    <!-- begin::Item -->
    <div class="d-flex flex-stack mb-5">
      <!-- begin::Section -->
      <div class="d-flex align-items-center me-2">
        <!-- begin::Symbol -->
        <div class="symbol symbol-35px me-3">
          <div class="symbol-label bg-light">
            <img
              src="./assets/media/svg/brand-logos/figma-1.svg"
              alt=""
              class="h-50"
            />
          </div>
        </div>
        <!-- end::Symbol -->

        <!-- begin::Title -->
        <div>
          <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bolder">
            Top Sales
          </a>
          <div class="fs-7 text-muted fw-bold mt-1">PitStop Emails</div>
        </div>
        <!-- end::Title -->
      </div>
      <!-- end::Section -->

      <!-- begin::Label -->
      <div class="badge badge-light fw-bold py-4 px-3">+82$</div>
      <!-- end::Label -->
    </div>
    <!-- end::Item -->

    <!-- begin::Item -->
    <div class="d-flex flex-stack">
      <!-- begin::Section -->
      <div class="d-flex align-items-center me-2">
        <!-- begin::Symbol -->
        <div class="symbol symbol-35px me-3">
          <div class="symbol-label bg-light">
            <img
              src="./assets/media/svg/brand-logos/vimeo.svg"
              alt=""
              class="h-50"
            />
          </div>
        </div>
        <!-- end::Symbol -->

        <!-- begin::Title -->
        <div class="py-1">
          <a href="#" class="fs-6 text-gray-800 text-hover-primary fw-bolder">
            Top Engagement
          </a>

          <div class="fs-7 text-muted fw-bold mt-1">KT.com</div>
        </div>
        <!-- end::Title -->
      </div>
      <!-- end::Section -->

      <!-- begin::Label -->
      <div class="badge badge-light fw-bold py-4 px-3">+82$</div>
      <!-- end::Label -->
    </div>
    <!-- end::Item -->
  </div>
  <!-- end::Items -->
</div>
<!-- end::Body -->
