import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Select2UpdateEvent } from 'ng-select2-component';
import { GridsterConfigService } from 'src/app/modules/common/services/gridster.service';
import {  OrganizationService} from "../../../../../modules/common/services/organization.service";
import { ConnectBaseService } from 'src/app/_metronic/kt/_utils/base.service';


@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent implements OnInit {
  listWidget: any;
  selectedIsView: any;
  overlay = false;
  startDate: any; 
  endDate: any;
  startDateTouched = false;
  endDateTouched = false;
  filterValue:any = ['1'];
  newstatus1: any = [
    {
      value: '1',
      label: "ALL"
    },
    {
      value: '2',
      label: "Hiring & On-boarding"
    },
    {
      value: '3',
      label: "Support Ticket"
    },
    {
      value: '4',
      label: "Salary"
    },
    {
      value: '5',
      label: "Time, Attendance & Leave"
    },
    {
      value: '11',
      label: "Upcoming Birthdays"
    },
    // {
    //   value: '7',
    //   label: "Customer Management"
    // },
    // {
    //   value: '8',
    //   label: "Product Management"
    // },
    // {
    //   value: '9',
    //   label: "Tax-Paid"
    // },
    // {
    //   value: '10',
    //   label: "Tax-Received"
    // },
  ]
  filter: any;
  artiFactsItems: any;
  firstTime:any = true
  constructor(private GridsterConfigService:GridsterConfigService,private baseService:ConnectBaseService, private datePipe: DatePipe, private OrganizationService:OrganizationService) {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 365);
    const formatDate = (date: Date) => {
      let day = String(date.getDate()).padStart(2, '0');
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };
    this.startDate = formatDate(startDate);
    this.endDate = formatDate(endDate);

    if (this.startDate && this.endDate) {
      const data = {
        'start_date': this.startDate,
        'end_date': this.endDate
      }
      this.OrganizationService.setDateValue(data)
    }
   }

  ngOnInit(): void {
    let organization: any = localStorage.getItem('organization');
    organization = JSON.parse(organization);
    if(organization?.user_role !== 'ADMIN'){
      this.baseService.currentArtiFactsItems$?.subscribe({
        next: (value) => {
          this.artiFactsItems = value
          if (value) {
            if (!this.findObjectBySlug('members', 'organization')?.is_read && !this.findObjectBySlug('candidates-management', 'hiring')?.is_read && (!this.findObjectBySlug('jobs', 'hiring')?.is_read) || !this.findObjectBySlug('jobs', 'hiring')?.is_read || !this.findObjectBySlug('jobs', 'hiring')?.is_read) {
              
              this.removeFilter(1)
            }
            if (!this.findObjectBySlug('all-request')?.is_read && !this.findObjectBySlug('all-request')?.is_create && !this.findObjectBySlug('all-request')?.is_update && !this.findObjectBySlug('all-request')?.is_delete) {
              this.removeFilter(2)
            }
            if (!this.findObjectBySlug('salary')?.is_read || !this.findObjectBySlug('salary')?.is_create || !this.findObjectBySlug('salary')?.is_update || !this.findObjectBySlug('salary')?.is_delete) {
              this.removeFilter(3)
            }
            if (!this.findObjectBySlug('attendance')?.is_read && !this.findObjectBySlug('attendance')?.is_create && !this.findObjectBySlug('attendance')?.is_update && !this.findObjectBySlug('attendance')?.is_delete && !this.findObjectBySlug('apply-leave')?.is_read && !this.findObjectBySlug('apply-leave')?.is_create && !this.findObjectBySlug('apply-leave')?.is_update && !this.findObjectBySlug('apply-leave')?.is_delete) {
              this.removeFilter(4)
            }
            if (!this.findObjectBySlug('vendor-orders', 'sales')?.is_read || !this.findObjectBySlug('vendor-orders', 'sales')?.is_create || !this.findObjectBySlug('vendor-orders', 'sales')?.is_update) {
              this.removeFilter(9)
            }
            if (!this.findObjectBySlug('customer-orders', 'sales')?.is_read || !this.findObjectBySlug('customer-orders', 'sales')?.is_create || !this.findObjectBySlug('customer-orders', 'sales')?.is_update) {
              this.removeFilter(8)
            }
          }
        },
      })
    }
  }
  

  removeFilter(index: number) {
    this.newstatus1.splice(index,1)
  }

  findObjectBySlug(slug: any, parentSlug: any = null): any {
    if (this.artiFactsItems) {
      for (const item of this.artiFactsItems) {
        if (parentSlug && item.slug === parentSlug) {
          if (item.slug === slug) {
            return item;
          }
          if (item.children) {
            const childResult: any = this.findObjectBySlugInChildren(slug, item.children);
            if (childResult) {
              return childResult;
            }
          }
        }
        if (!parentSlug) {
          if (item.slug === slug) {
            return item;
          }
          if (item.children) {
            const childResult: any = this.findObjectBySlugInChildren(slug, item.children);
            if (childResult) {
              return childResult;
            }
          }
        }
      }
    }
    return null;
  }

  private findObjectBySlugInChildren(slug: any, children: any[]): any {
    for (const child of children) {
      if (child.slug === slug) {
        return child;
      }
      if (child.children) {
        const childResult: any = this.findObjectBySlugInChildren(slug, child.children);
        if (childResult) {
          return childResult;
        }
      }
    }
    return null;
  }
  
  logDateRange(type?: any, type2?: any): void {
    if (this.startDate && this.endDate) {
      const startDateObj = typeof this.startDate === 'string' ? new Date(this.startDate) : this.startDate;
      const endDateObj = typeof this.endDate === 'string' ? new Date(this.endDate) : this.endDate;
      endDateObj.setHours(23, 59, 59, 999);
      this.endDate = new Date(Math.max(startDateObj.getTime(), endDateObj.getTime()));
        this.startDate = this.datePipe.transform(startDateObj, 'yyyy-MM-dd') ?? '';
        this.endDate = this.datePipe.transform(endDateObj, 'yyyy-MM-dd') ?? '';
        
        if (type2 === 'startdate' && type === 'enddate') {
          const data ={
            'start_date':this.startDate,
            'end_date':this.endDate
          }
          this.OrganizationService.setDateValue(data)
      }
    } 
  }

  action(type:any){
    this.listWidget = this.GridsterConfigService.listWidget;
    if(type == 'Pin'){
      this.GridsterConfigService.emit(this.filter)
    }else{
      this.GridsterConfigService.emit(type.toLowerCase());
    }
  }

  isChecked(isView: boolean): boolean {
    return isView;
  }
  

  onCheckboxChange(list: any): void {
    list.is_view = !list.is_view;
  }

  update(key: any, event: Select2UpdateEvent<any>, type: any) {
    key = event.value;
    this.filter = event.options.length > 0 ? event.options : [{"value": "1","label": "ALL"}];
    if(key?.length === 0){
      this.filterValue = ['1']
    }
    if(this.firstTime) {
      this.firstTime = false
      return true
    }
    this.GridsterConfigService.emit(this.filter)
  }

  updateDateRange() {
    this.startDateTouched = true;
    this.endDateTouched = true;
    if (this.startDate && this.endDate) {
      const data ={
        'start_date':this.startDate,
        'end_date':this.endDate
      }
      console.log(data);
      this.OrganizationService.setDateValue(data)
    }
  }

}
