<!--begin::Tiles Widget 12-->
<div
  class="card card-custom"
  [ngClass]="cssClass"
  [ngStyle]="{ height: widgetHeight }"
>
  <div class="card-body">
    <span
      [inlineSVG]="'./assets/media/icons/duotune/finance/fin006.svg'"
      class="svg-icon svg-icon-3x"
      [ngClass]="svgCSSClass"
    ></span>
    <div class="text-dark font-weight-bolder font-size-h2 mt-3">8,600</div>
    <a
      href="#"
      class="text-muted text-hover-primary font-weight-bold font-size-lg mt-1"
      >New Customers</a
    >
  </div>
</div>
<!--end::Tiles Widget 12-->
