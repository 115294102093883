import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize, first } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { AuthModel } from '../models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { OrganizationService } from '../../common/services/organization.service';
import { apiUrl } from 'src/app/_metronic/kt/_utils/api-url.enum';
import { APIService } from '../../common/services/api.service';

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(

    private authHttpService: AuthHTTPService,
    private router: Router,
    private organizationService:OrganizationService,
    private apiService: APIService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
    
    this.subDomain()?.pipe(first())
      .subscribe((user: UserModel | undefined) => {
        if (user) {
          let lsValue:any = localStorage.getItem(this.authLocalStorageToken);
          const authData = JSON.parse(lsValue);
          this.router.navigate(['/']);
        }
      }, (error) => {
        
      });;

  }

  resetCurrentUser():void {
    this.currentUserSubject.next(undefined);
  }

  // public methods
  login(email: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      switchMap((auth: AuthModel) => {
        return of(this.setAuthFromLocalStorage(auth)).pipe(
          switchMap(() => this.getUserByToken())
        );
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  joinWorkPlace(){
    this.organizationService.joinWorkPlace().subscribe({
      next(value) {},
      error(err) {}
    })
  }
  subDomain() {
    const queryParams = new URLSearchParams(window.location.search);
    const keyy:any = queryParams.get('key');
    if(keyy){
      localStorage.setItem('Org_Id', keyy);
      this.organizationService.triggerEventOrg();
    }
    const valueString: any = queryParams.get('value');
    if (valueString) {
      const body = {
        "refresh": valueString
      }
      return this.authHttpService.refreshToken(body).pipe(
        switchMap((auth: AuthModel) => {
          return of(this.setAuthFromLocalStorage(auth)).pipe(
            switchMap(() => this.getUserByToken())
          );
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );   
    }

  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: UserType) => {
        if (user) {
          this.authHttpService.setMe(user);
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  changePassword(verificationkey: any, body: any): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .changePassword(verificationkey, body)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  changePasswordValidation(body: any): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .changePasswordValidation(body)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  emailVerify(email_verification_key: any) {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .verifyEmail(email_verification_key)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  resetPassword() {
    this.apiService.listItem(apiUrl.reset_password).subscribe((data) => {
      if (data && data?.url) {
        const resetLink = data?.url;
        window.open(resetLink, '_blank');
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
