import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { Select2Module } from 'ng-select2-component';
import { OrganizationService } from 'src/app/modules/common/services/organization.service';
import { ProductService } from 'src/app/modules/common/services/product.service';
import { VendorService } from 'src/app/modules/common/services/vendor.service';

@Component({
  selector: 'app-quotation-item-service',
  templateUrl: './quotation-item-service.component.html',
  styleUrls: ['./quotation-item-service.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, Select2Module, InlineSVGModule]
})
export class QuotationItemServiceComponent  implements OnInit {
  overlay = false;
  searchControl: any = new FormControl('');
  form: FormGroup;
  uom: any;
  newListUom: any[];
  productvariationlist: any;
  newProductvariationlist: any[];
  product: any;
  newProductlist: any[];

  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private vendorservice: VendorService,
    private productservice: ProductService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.initForm();
    this.getproduct();
    this.listUom();
    this.getProductvariationlist();
  }

  initForm() {
    this.form = this.fb.group({
      items: this.fb.array([this.createItem()])
    });
  }

  createItem(): FormGroup {
    return this.fb.group({
      sales_product: [''],
      description: [''],
      product_variant: [''],
      uom: ['',],
      alternate_uom: [''],
      hsn_code: [''],
      qty: [''],
      delivery_date: [''],
      comment: [''],
      store_qty: ['']
    });
  }

  get items(): FormArray {
    return this.form.get('items') as FormArray;
  }

  addItem() {
    this.items.push(this.createItem());
  }

  removeItem(index: number) {
    if (this.items.length > 1) {
      this.items.removeAt(index);
    }
  }

  listUom() {
    this.vendorservice.getUom(true).subscribe({
      next: (value) => {
        this.uom = value.results;
        this.newListUom = this.organizationService.convertToValueLabelArray(value.results, 'id', 'name');
      }, error: (err) => {
      },
    })
  }

  getProductvariationlist() {
    this.productservice.getproducvariation(true).subscribe({
      next: (response: any) => {
        this.productvariationlist = response.results;
        this.newProductvariationlist = this.productservice.convertToValueLabelArray(this.productvariationlist, 'id', 'name');
      }, error: (err) => {
      },
    });
  }

  getproduct() {
    this.productservice.getProduct(undefined, undefined, 'publish_products=true').subscribe({
      next: (response: any) => {
        this.product = response.results;
        this.newProductlist = this.productservice.convertToValueLabelArray(this.product, 'id', 'name');
        this.cdr.detectChanges();
      }, error: (err) => {
      },
    })
  }

  emitFormData() {
    return this.form.value;
  }

}
