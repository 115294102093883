<div class="row g-5">
  <div class="col-lg-6">
    <div class="row g-5">
      <div class="col-lg-6">
        <div class="card card-scroll card-stretch-50 card-bordered mb-5">
          <div class="card-header">
            <h4 class="card-title">Buyer Details</h4>
            <div class="card-toolbar">
              <a (click)="openModal()" href="#" class="btn btn-icon btn-sm btn-active-color-primary"
                data-kt-card-action="edit" data-bs-toggle="tooltip" title="Edit" data-bs-dismiss="click">
                <i class="fas fa-edit fs-2"><span class="path1"></span><span class="path2"></span></i>
              </a>
            </div>
          </div>
          <div class="card-body">
            <h5>Test Company</h5>
            <label class="col-lg-12 fw-bold text-muted">Main Address, Mumbai (Maharashtra), India - 400001</label>
            <label class="col-lg-4 fw-bold text-muted">GSTIN:</label>
            <label class="col-lg-8 fw-bold text-muted"><a class="text-primary"><i class="fas fa-map-marker-alt"></i>
                Place
                of Supply</a></label>
          </div>
        </div>
        <div class="card card-scroll card-stretch-50 card-bordered mb-5">
          <div class="card-header">
            <h4 class="card-title">Supplier Details</h4>
            <div class="card-toolbar">
              <a (click)="openModal()" href="#" class="btn btn-icon btn-sm btn-active-color-primary"
                data-kt-card-action="edit" data-bs-toggle="tooltip" title="Edit" data-bs-dismiss="click">
                <i class="fas fa-edit fs-2"><span class="path1"></span><span class="path2"></span></i>
              </a>
            </div>
          </div>
          <div class="card-body">
            <h5>Test Company</h5>
            <label class="col-lg-12 fw-bold text-muted">Main Address, Mumbai (Maharashtra), India - 400001</label>
            <label class="col-lg-4 fw-bold text-muted">GSTIN:</label>
            <label class="col-lg-8 fw-bold text-muted"><a class="text-primary"><i class="fas fa-map-marker-alt"></i>
                Place
                of Supply</a></label>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card card-scroll card-stretch-50 card-bordered mb-5">
          <div class="card-header">
            <h4 class="card-title">Delivery Location</h4>
            <div class="card-toolbar">
              <a (click)="openModal()" href="#" class="btn btn-icon btn-sm btn-active-color-primary"
                data-kt-card-action="edit" data-bs-toggle="tooltip" title="Edit" data-bs-dismiss="click">
                <i class="fas fa-edit fs-2"><span class="path1"></span><span class="path2"></span></i>
              </a>
            </div>
          </div>
          <div class="card-body">
            <h5>Test Company</h5>
            <label class="col-lg-12 fw-bold text-muted">Main Address, Mumbai (Maharashtra), India - 400001</label>
            <label class="col-lg-4 fw-bold text-muted">GSTIN:</label>
            <label class="col-lg-8 fw-bold text-muted"><a class="text-primary"><i class="fas fa-map-marker-alt"></i>
                Place
                of Supply</a></label>
          </div>
        </div>
      </div>      
    </div>
  </div>

  <div class="col-lg-6">
    <div class="card card-scroll card-stretch card-bordered mb-5">
      <div class="card-header">
        <h4 class="card-title">Primary Document Details</h4>
      </div>
      <div class="card-body d-flex flex-column pb-0">
        <form [formGroup]="documentDetailForm">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="required">Document Number</label>
              <input type="text" class="form-control" formControlName="document_series_number">
            </div>
            <div class="col-md-6 mb-3">
              <label>Document Series</label>
              <select2 [overlay]="overlay" class="form-control form-control-solid" [data]="newDocumentSeries"
              formControlName="document_series"></select2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="required">Delivery Date</label>
              <input type="date" class="form-control" formControlName="delivery_date">
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="required">Bidding Start Date</label>
              <input type="date" class="form-control" formControlName="bidding_start_date">
            </div>
            <div class="col-md-6 mb-3">
              <label class="required">Bidding End Date</label>
              <input type="date" class="form-control" formControlName="bidding_end_date">
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="document-date" class="form-label">Bidding End Date</label>
            <input type="date" class="form-control" id="document-date" value="2024-10-07">
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="document-date" class="form-label">Payment Term</label>
              <select2 [overlay]="overlay" class="form-control form-control-solid" id="selec2-2"
                formControlName="payment_term" [data]="terms"></select2>
            </div>
            <div class="col-md-6 mb-3">
              <label for="document-date" class="form-label">Store</label>
              <select2 [overlay]="overlay" formControlName="store_type" class="form-control form-control-solid"
                [data]="rawMaterial"></select2>
            </div>
          </div>
        </form>
      </div>
    </div>
</div>

<app-modal #modal [modalConfig]="modalConfig">
  <div id="kt_app_content" class="app-content flex-column-fluid">
    <div id="kt_app_content_container" class="app-container">
      <div class="card card-flush">
        <div class="card-body">
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="kt_ecommerce_settings_general" role="tabpanel">
              <form id="kt_ecommerce_settings_general_form" [formGroup]="billingLocationForm">
                <div class="row fv-row mb-5">
                  <div class="col-md-3 text-md-end">
                    <label class="fs-6 fw-semibold form-label mt-3">
                      <span class="required">Location</span>
                    </label>
                  </div>
                  <div class="col-md-9">
                      <select2 class="form-control form-control-solid" formControlName="billing_address"
                        [data]="selectFormatOrgWorkAddresses"></select2>
                      <app-form-control-error [control]="documentDetailForm.get('billing_address')"></app-form-control-error>
                      <div class="text-muted fs-7">
                        <b>Address: </b> {{ getFullWorkAddress(documentDetailForm.controls?.billing_address?.value) }}
                      </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <span role="alert">
        {{ message }}
      </span>
    </div>
  </ng-container>
</ng-template>