import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TranslationService } from '../../../../../../modules/i18n';
import { AuthService, UserType } from '../../../../../../modules/auth';
import { environment } from 'src/environments/environment.dev';
import { Router } from '@angular/router';
import { RoutingUtil } from 'src/app/_metronic/kt/_utils/routingUtils';
import { APIService } from 'src/app/modules/common/services/api.service';

@Component({
  selector: 'app-user-inner',
  templateUrl: './user-inner.component.html',
})
export class UserInnerComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  class = `menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px`;
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';

  currentOrgId = Number(localStorage.getItem('Org_Id'));
  language: LanguageFlag;
  user$: Observable<UserType>;
  langs = languages;
  private unsubscribe: Subscription[] = [];
  isview: boolean = false;
  appPreviewDocsUrl: string = environment.appPreviewDocsUrl;

  constructor(
    private auth: AuthService,
    private router: Router,
    private routingutil: RoutingUtil,
    private translationService: TranslationService,
    private apiService: APIService
  ) { }

  ngOnInit(): void {
    this.user$ = this.auth.currentUserSubject.asObservable();
    this.setLanguage(this.translationService.getSelectedLanguage());
    if (!window.location.host.startsWith(environment.appName)) {
      this.isview = true;
    } else {
      this.isview = false;
    } 
  }

  logout() {
    this.auth.logout();
    localStorage.clear();
    const url = `http://${environment.appName}.${environment.rootDomain}/home?logout=yes`;
    if (window.navigator.userAgent.match(/Android/i) || window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      document.location.reload();
    } else {
      document.location.reload();
    }
  }
  selectLanguage(lang: string) {
    this.translationService.setLanguage(lang);
    this.setLanguage(lang);
    // document.location.reload();
  }

  setLanguage(lang: string) {
    this.langs.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        this.language = language;
      } else {
        language.active = false;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  navigate() {
    let org: any = localStorage.getItem('organization');
    const value = JSON.parse(org);
    this.router.navigateByUrl(`/organization/${value.id}/userProfile/${value.member_id}/personal`)
    setTimeout(() => {
      this.routingutil?.filterArrayBySlug();
    }, 100)
  }

  resetPassword() {
    this.auth.resetPassword();
  }
}

interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: './assets/media/flags/united-states.svg',
  },
  {
    lang: 'zh',
    name: 'Mandarin',
    flag: './assets/media/flags/china.svg',
  },
  {
    lang: 'es',
    name: 'Spanish',
    flag: './assets/media/flags/spain.svg',
  },
  {
    lang: 'ja',
    name: 'Japanese',
    flag: './assets/media/flags/japan.svg',
  },
  {
    lang: 'de',
    name: 'German',
    flag: './assets/media/flags/germany.svg',
  },
  {
    lang: 'fr',
    name: 'French',
    flag: './assets/media/flags/france.svg',
  },
];
