<div class="d-none">
  <!--begin::Search-->
<div class="app-navbar-item align-items-stretch" [ngClass]="itemClass">
  <!--begin::Search-->
  <div id="kt_header_search" class="header-search d-flex align-items-stretch" data-kt-search-keypress="true"
    data-kt-search-min-length="2" data-kt-search-enter="enter" data-kt-search-layout="menu"
    data-kt-menu-trigger="{default: 'click'}" data-kt-menu-overflow="false" data-kt-menu-permanent="true"
    data-kt-menu-placement="bottom-end" data-kt-search="true">
    <!--begin::Search toggle-->
    <div class="d-flex align-items-center" data-kt-search-element="toggle" id="kt_header_search_toggle">
      <div [ngClass]="btnClass">
        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen021.svg'" class="svg-icon"
          [ngClass]="btnIconClass"></span>
      </div>
    </div>
    <!--end::Search toggle-->
    <app-search-result-inner data-kt-search-element="content" data-kt-menu="true"
      class="menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px"></app-search-result-inner>
  </div>

</div>
<!--end::Search-->

<!--begin::Activities-->
<div class="app-navbar-item" [ngClass]="itemClass">
  <!--begin::Drawer toggle-->
  <div [ngClass]="btnClass" id="kt_activities_toggle">
    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen032.svg'" class="svg-icon"
      [ngClass]="btnIconClass"></span>
  </div>
  <!--end::Drawer toggle-->
</div>
<!--end::Activities-->

<!--begin::Notifications-->
<div class="app-navbar-item" [ngClass]="itemClass">
  <!--begin::Menu- wrapper-->
  <div [ngClass]="btnClass" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent"
    data-kt-menu-placement="bottom-end">
    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen022.svg'" class="svg-icon"
      [ngClass]="btnIconClass"></span>
  </div>
  <app-notifications-inner></app-notifications-inner>
  <!--end::Menu wrapper-->
</div>
<!--end::Notifications-->

<!--begin::Chat-->
<div class="app-navbar-item" [ngClass]="itemClass">
  <div [ngClass]="btnClass" class="position-relative" id="kt_drawer_chat_toggle">
    <span [inlineSVG]="'./assets/media/icons/duotune/communication/com012.svg'" class="svg-icon"
      [ngClass]="btnIconClass"></span>
    <span
      class="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink">
    </span>
  </div>
</div>
<!--end::Chat-->

<!--begin::Quick links-->
<div class="app-navbar-item" [ngClass]="itemClass">
  <!--begin::Menu wrapper-->
  <div [ngClass]="btnClass" data-kt-menu-trigger="click" data-kt-menu-attach="parent"
    data-kt-menu-placement="bottom-end">
    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen025.svg'" class="svg-icon"
      [ngClass]="btnIconClass"></span>
  </div>
  <!--end::Menu wrapper-->
  <app-quick-links-inner></app-quick-links-inner>
</div>
<!--end::Quick links-->

<!--begin::Theme mode-->
<div class="app-navbar-item" [ngClass]="itemClass">
  <app-theme-mode-switcher [toggleBtnClass]="btnClass"
    toggleBtnClass="{`btn-active-light-primary btn-custom ${toolbarButtonHeightClass}`}">
  </app-theme-mode-switcher>
</div>
</div>
<!--end::Theme mode-->

<div class="app-navbar-item ms-1 ms-md-3" (click)="toggleFullScreenMode()" [ngbTooltip]="fullScreenElemClicked ? 'Exit Full Screen': 'Expand to Full Screen'">
  <div class="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px" id="kt_activities_toggle">
    <span class="svg-icon svg-icon-2 svg-icon-md-1">
      <i class="fa fa-expand"></i>
    </span>
  </div>
</div>

<!--begin::User menu-->
<div class="app-navbar-item" [ngClass]="itemClass">
  <!--begin::Menu wrapper-->
  <div class="cursor-pointer symbol" [ngClass]="userAvatarClass"
  data-kt-menu-trigger="{default: 'click'}"
    data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end"
  >
    <img *ngIf="organization?.logo != null" src="{{ organization?.logo }}" />
    <!-- <img *ngIf="organization?.logo == null" src="./assets/media/avatars/300-1.jpg" /> -->

    <ng-container *ngIf="organization?.logo == null">
      <div class="custom-name" [ngStyle]="{ background: geColor }">
        {{
        getShortName(organization?.email)
        }}
      </div>
    </ng-container>
  </div>
  <app-user-inner data-kt-menu='true'></app-user-inner>
  <!--end::Menu wrapper-->
</div>
<!--end::User menu-->

<!--begin::Header menu toggle-->
<!-- <ng-container *ngIf="appHeaderDefaulMenuDisplay">
  <div class="app-navbar-item d-lg-none ms-2 me-n3" title="Show header menu">
    <div class="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_header_menu_toggle">
      <span [inlineSVG]="'./assets/media/icons/duotune/text/txt001.svg'" class="svg-icon"
        [ngClass]="btnIconClass"></span>
    </div>
  </div>
</ng-container> -->
<!--end::Header menu toggle-->
