import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {

  isLoading = new Subject<boolean>();
  
  // Change to BehaviorSubject so that we can use .getValue()
  private isForceLoading = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  show(forceLoading: boolean = false) {
    if (forceLoading) {
      this.isForceLoading.next(true);
    }
    this.isLoading.next(true);
  }

  hide() {    
    if (!this.isForceLoading.getValue()) {
      this.isLoading.next(false);
    }     
  }

  clearForceLoading() {
    this.isForceLoading.next(false);
    this.hide();
  }
}