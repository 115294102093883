import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { RoutingUtil } from 'src/app/_metronic/kt/_utils/routingUtils';


@Injectable({ providedIn: 'root' })
export class OrganizationGuard implements CanActivate {

    private jsonData = [];
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
    constructor(
        private router: Router,
        private routingUtil: RoutingUtil
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const lsValue: any = localStorage.getItem(this.authLocalStorageToken);
        const authData = JSON.parse(lsValue);
        const orgId = Number(localStorage.getItem('Org_Id'));
        let module: any = localStorage.getItem('artiFacts');
        this.jsonData = JSON.parse(module);
        let childSlug: any;

        if (orgId) {
            const urlSegments = state.url.split('/');
            let parentSlug = urlSegments[1];
            const lastSlug = urlSegments[urlSegments.length - 1];

            if (Object.keys(this.routingUtil.subModuleJsonData).includes(lastSlug)) {
                parentSlug = lastSlug;
            } else {
                const isSecondSegmentNumber = !isNaN(Number(urlSegments[2]));
                childSlug = isSecondSegmentNumber ? urlSegments[3] : urlSegments[2];
            }

            // Find the parent object that matches the parentSlug
            const parent: any = this.jsonData.find((item: any) => item.slug === parentSlug);

            if (!parent) {
                this.router.navigate(['/error/404']);
                return false;
            }

            if (!childSlug) {
                return true;
            }

            if (parent.children && parent.children.length > 0) {
                const childMatch = parent.children.some((child: any) => child.slug === childSlug);
                if (childMatch) {
                    return true;
                }
            }

            this.router.navigate(['/error/404']);
            return false;
        }
        if (authData.is_org_create) {
            this.router.navigate(['/organization/add']);
        }
        return !authData.is_org_create;
    }
}
