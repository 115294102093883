
  <div class="menu-item px-3">
    <div class="menu-content fs-6 text-dark fw-bolder px-3 py-4">
      Organization List
    </div>
  </div>
  
  <div class="separator mb-3 opacity-75"></div>
  <div class="h-150px overflow-auto">
    <div class="menu-item px-3" *ngIf="roleId === 'ADMIN'">
      <a class="menu-link px-3 py-2" [ngClass]="{'active': 'add_new_organization' === selectedOrgId}"  (click)="orgName(null, true)">
        <span class='menu-title'>Add New Organization</span>
      </a>
    </div>
  
    <div class="menu-item px-3" *ngFor="let item of org, let i = index">
        <a class="menu-link px-3 py-2" [ngClass]="{'active': item.id === selectedOrgId}" (click)="orgName(item,false,true)">
          <span class='menu-title'>{{item.name}}</span>
        </a>
    </div>
</div>
