import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Select2Module, Select2UpdateEvent } from 'ng-select2-component';
import { Observable, of } from 'rxjs';
import { en_US_rawMaterials } from 'src/app/_metronic/kt/_utils/en_us';
import { OrganizationService } from 'src/app/modules/common/services/organization.service';


export interface TableHeaderOptions {
  title: string;
  required: boolean,
  readonly: boolean,
  rowDelete: boolean,
  control_name: any | null;
  class_name: string;
  show: boolean,
  plain_text: boolean,
  input_type: string,
  input_class: any,
  custom_condition: any
}

@Component({
  selector: 'app-bom-table',
  templateUrl: './bom-table.component.html',
  styleUrls: ['./bom-table.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, Select2Module]
})
export class BOMTableComponent implements OnInit {
  @Output() valueEmitted = new EventEmitter<any>();
  @Input() tableValues: any
  @Input() tableHeader: Array<TableHeaderOptions> = [];
  @Input() tableName: any;
  formIvalid: any = false;
  @Input() set formSubmitted(value: any) {
    if (value) {
      this.formIvalid = value
    }
  };
  @Input() set readonly(value: any) {
    this.readonlyValue = value;
    if (value) {
      if (value != 'default') {
        this.enableForm()
      }
    } else {
      if (value != undefined) {
        this.disableForm()
      }
    }
  }
  @Input() defaultRow: boolean = true
  @Input() componentName: any;
  @Input() addMultiRowName: string = 'N/A';
  @Input() customTable: any = 'table-lg'
  @Input() dropDownValue: any = []
  readonlyValue: any;
  form: FormGroup;
  overlay: any = false
  tableitem: any;

  constructor(private fb: FormBuilder, private orgService: OrganizationService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      rows: this.fb.array([]),
    });
    if (this.tableValues && this.tableValues.length > 0) {
      this.populateFormWithData(this.tableValues);
    } else {
      if (this.defaultRow) {
        this.addNewRow('data');
      }
    }
  }


  disableForm() {
    this.form.disable();
    this.rows.controls.forEach((row) => row.disable());
    this.cdr.detectChanges()
  }
  enableForm() {
    this.form.enable();
    this.rows.controls.forEach((row) => row.enable());
    this.cdr.detectChanges()
  }


  isControlValid(control: AbstractControl): boolean {
    if (control.disabled) {
      control.enable();
      const isValid = this.form.valid;
      control.disable();
      return isValid;
    }
    return this.form.valid;
  }
  ngDoCheck() {
    if (this.tableValues !== this.tableitem) {
      this.tableitem = this.tableValues
      if (this.tableValues && this.tableValues.length > 0) {
        if (this.rows?.value?.length <= this.tableValues?.length) {
          this.populateFormWithData(this.tableValues);
          if (this.readonlyValue === undefined || this.readonlyValue === false) {
            this.disableForm()
          }
        }
      }
    }
  }

  get rows(): FormArray {
    return this.form.get('rows') as FormArray;
  }

  addNewRow(type?: any) {
    if (type) {
      this.rows.reset()
    }
    const rowGroup = this.fb.group({});
    this.tableHeader.forEach((header) => {
      if (header?.control_name) {
        const validators = [];
        if (header.required) {
          validators.push(Validators.required);
        }
        rowGroup.addControl(header.control_name, new FormControl(null, validators));
      }
    });
    this.rows.push(rowGroup);
  }
  removeRow(rowGroup: any) {
    const index = this.rows.controls.indexOf(rowGroup);
    if (index !== -1) {
      this.rows.removeAt(index);
    }
  }
  populateFormWithData(data: any[]) {
    this.rows.clear()
    data.forEach((rowData) => {
      const rowGroup = this.fb.group({});
      this.tableHeader.forEach((header) => {
        if (header?.control_name) {
          const value = rowData[header.control_name] !== undefined ? rowData[header.control_name] : null;
          const validators = [];
          if (header.required) {
            validators.push(Validators.required);
          }
          rowGroup.addControl(header.control_name, new FormControl(value, validators));
        }
      });
      this.rows.push(rowGroup);
    });
  }

  restrictInput(event: any, inputType: string) {
    if (inputType === 'number') {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
  }

  isInvalid(controlName: string, index: number): boolean {
    const control = this.rows?.at(index)?.get(controlName);
    if (control) {
      const isInvalid = control.invalid && (control.touched || control.dirty || this.formIvalid);
      if (isInvalid) {
        // this.orgService.scrollToError(this.form);
      }
      return isInvalid;
    }
    return false;
  }


  update(key: any, event: Select2UpdateEvent<any>, type: any, item?: any) {
    const data: any = event?.options
    if (data?.length > 0 && type) {
      let list: any
      if (this.componentName === 'BOM' && type === 'sku') {
        list = {
          "uom": "default_uom",
          "uom_name": "default_uom_name",
          "cost_allocation": 100,
          "qty": 1,
          "category_id": en_US_rawMaterials.find((item) => item?.value === data[0].store_type)?.label
        }
        this.patchRowValue(key, event.options[0], list, ['sku'])
      }
    }
  }

  getDropdownValue(controlName: any,index:any,custom_condition?:any): any {
    let results = this.dropDownValue?.[controlName || '']
    if(custom_condition?.multiDropDown){
      if (index >= 0 && index < this.rows.length) {
        const rowGroup:any = this.rows.at(index);
        if(rowGroup?.value?.sku && this.dropDownValue?.sku){
          results = this.dropDownValue.sku.find((item:any)=> item?.sku === rowGroup?.value?.sku).product_variation_groups
        }
      }
    }
    
    return results;
  }

  valueEmit() {
    this.valueEmitted.emit();
  }

  patchRowValue(index: number, values: { [key: string]: any }, mapping?: { [key: string]: any }, excludeFields: any = []) {
    if (this.rows.at(index)) {
      const formGroup = this.rows.at(index) as FormGroup;
      const results: { [key: string]: any } = {};
      const staticKeys = ['qty', 'cost_allocation'];

      if (mapping) {
        for (const [key, mappedKey] of Object.entries(mapping)) {
          if (!excludeFields.includes(key)) {
            if (key == 'category_id') {
              results[key] = values[mappedKey] ?? mappedKey;
            } else if (staticKeys.includes(key)) {
              results[key] = this.tableValues?.find((item: any) => item?.product == values.product)?.[key];
            } else {
              results[key] = values[mappedKey] ?? null;
            }
          }
        }
      }

      for (const key of Object.keys(values)) {
        if (!results.hasOwnProperty(key) && !excludeFields.includes(key)) {
          if (key == 'qty') {
            results[key] = this.tableValues?.find((item: any) => item?.product == values.product)?.qty;
          } else {
            results[key] = values[key];
          }
        }
      }
      formGroup.patchValue(results);
    } else {
      console.error(`No row found at index ${index}`);
    }
  }

  getControlValue(controlName: string, index: number): Observable<any> {
    if (index >= 0 && index < this.rows.length) {
      const rowGroup = this.rows.at(index);
      const control = rowGroup.get(controlName);
      if (control) {
        control.valueChanges.subscribe((value) => {
          this.valueEmitted.emit({ type: 'controlValueChanges', data: { controlName: controlName, index: index, value: value }, tableName: this.tableName, fullValue: rowGroup.value });
        });
        return control.valueChanges;
      }
    }
    return of(null);
  }

  setControlValue(controlName: any, index: number, value: any): any {
    if (index >= 0 && index < this.rows.length) {
      const rowGroup = this.rows.at(index);
      const control = rowGroup.get(controlName);
      if (control) {
        control.setValue(value);
      }
    }
  }

  getFormControls() {
    return this.form.value;
  }

}
