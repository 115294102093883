import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import { TranslationService } from './modules/i18n';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { VersionCheckService } from './modules/common/services/version-check.service';
import { environment } from 'src/environments/environment';
import { AlertService } from './modules/common/services/alert.service';
import { SweetAlertOptions } from 'sweetalert2';
import { OrganizationService } from "./modules/common/services/organization.service";
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { RoutingUtil } from './_metronic/kt/_utils/routingUtils';
import { IntegrationService } from './modules/common/services/integration.service';

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  updateAvailable = false;
  currentVersion: any;
  successMsgTimeout:any = `${environment.successMsgTimeout}`
  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private versionCheckService: VersionCheckService,
    private alert: AlertService,
    private OrganizationService: OrganizationService,
    private router: Router,
    private routingutils: RoutingUtil,
    private integrationservice: IntegrationService,
  ) {
    
    const queryParams = new URLSearchParams(window.location.search);
    const key: any = queryParams.get('code');
    const state: any = queryParams.get('state');
    if (state && key) {      
      const newOrigin = window.location.origin.replace('app', state);
      window.location.href = `${newOrigin}?code=${key}`;
    }
    if(key){
      this.updateMicrosoft(key)
    }
  
    this.router.events.pipe(
      filter((event:any) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.routingutils.reverseNavigateToUrl(event.url)
      this.OrganizationService.getUserArtifacts().subscribe((data:any)=>{
      })
    });

    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }
  ngOnInit() {
    this.modeService.init();
    if (window.navigator.userAgent.match(/Android/i) || window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      this.versionCheck();

      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === 'granted') {
          PushNotifications.register();
        } else {
        }
      });

      PushNotifications.addListener('registration',
        (token: Token) => {
          this.registerDevice(token.value);
        }
      );

      PushNotifications.addListener('registrationError',
        (error: any) => {
        }
      );

      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
        }
      );
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
        }
      );
    } else {
    }
  }

  updateMicrosoft(data:any){
    const payload = {
      code: data
    }
    this.integrationservice.updateMicrosoft(payload).subscribe({
      next:(value)=> {
        const options: SweetAlertOptions = {
          toast: true,
          position: 'top',
          showConfirmButton: false,
          icon: 'success',
          timer: this.successMsgTimeout,
          title: `Outlook Calendar sync successfully`
        };
        this.alert.showAlert(options)
      },error(err) {
        
      },
    })
  }
  versionCheck() {
    this.versionCheckService.checkVersion()
      .then((latestVersion: any) => {
        const userAgent = window.navigator.userAgent;
        let appVersion;
  
        if (userAgent.match(/Android/i)) {
          appVersion = environment.appAndroid ? environment.androidAppVersion : '';
        } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
          appVersion = environment.appIos ? environment.iosAppVersion : '';
        }
  
        if (appVersion && latestVersion.version_number > appVersion) {
          const options: SweetAlertOptions = {
            title: `Important: Unlock new features and enhance security with our latest update. Update now!`,
            icon: 'warning',
            showCancelButton: !latestVersion.is_force_update,
            cancelButtonText: 'No',
            reverseButtons: true,
            confirmButtonText: 'Update',
          };
  
          this.alert.showAlert(options).then(result => {
            if (result.isConfirmed) {
              const url = latestVersion.url;
              this.redirectToPlayStore(url);
            }
          });
  
          if (latestVersion.is_force_update) {
            options.allowOutsideClick = false;
          }
          this.updateAvailable = true;
        }
      })
      .catch((error: any) => {
        console.error('Error checking for updates:', error);
      });
  }
  

  registerDevice(value: any) {
    
    const payload = {
      'registration_id': value
    }
    this.OrganizationService.registerDevice(payload).subscribe({
      next: (value) => {

      }, error: (err) => {
      },
    })
  }


  redirectToPlayStore(url:any): void {
    window.location.href = url;
  }
}
