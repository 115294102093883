import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ConnectBaseService } from 'src/app/_metronic/kt/_utils/base.service';

const API_USERS_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class OrganizationService extends ConnectBaseService {
  
  public orgSubject = new Subject<void>();
  private artifacts: any;
  private date:BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private taskValue:BehaviorSubject<any> = new BehaviorSubject<any>(null);
  org$ = this.orgSubject.asObservable();
  private userAccess: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private userArtifacts: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private searchTextSubject = new BehaviorSubject<string>('');
  errorEvent: EventEmitter<any> = new EventEmitter<any>();
  data: any = [];
  emitError(error: any) {
    this.errorEvent.emit(error);
  }

  checkUserAccess(userAccess: any, action: any) {
    const actions = action.split('_');

    let result = true;
    let isFirst = true;

    actions.forEach((act: any) => {
        if (act === 'and' || act === 'or') {
            isFirst = false;
        } else {
            if (act === 'all') {
                const allAccess = ['read', 'create', 'update', 'delete'].every(action => userAccess[`is_${action}`]);
                if (isFirst) {
                    result = allAccess;
                    isFirst = false;
                } else {
                    result = action === 'and' ? result && allAccess : result || allAccess;
                }
            } else {
              if(userAccess){
                const access = userAccess[`is_${act}`];
                  if (isFirst) {
                      result = access;
                      isFirst = false;
                  } else {
                      result = action === 'and' ? result && access : result || access;
                  }
              }else{
                return false
              }
            }
        }
    });

    return result;
}

  scrollToError(form: any) {
    if (form.invalid) {
        let firstInvalidControl = document.getElementsByClassName('fv-plugins-message-container')[0];
        if (firstInvalidControl) {
          const elem: any = firstInvalidControl.parentElement;
          elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
          (elem as HTMLElement).focus();
        }        
    }  
  }

  triggerEventOrg() {
    this.orgSubject.next();
  }
  setArtifactsValue(data: any): void {
    this.artifacts = data;
  }

  getArtifactsValue(): any {
    return this.artifacts;
  }

  setDateValue(data: any):void{
    this.date.next(data);
  }

  getDateValue():Observable<any>{
    return this.date.asObservable();
  }

  setTaskValue(data: any):void{
    this.taskValue.next(data);
  }

  getTaskValue():Observable<any>{
    return this.taskValue.asObservable();
  }

  setSearchText(searchText: string): void {
    this.searchTextSubject.next(searchText);
  }

  getSearchText(): Observable<string> {
    return this.searchTextSubject.asObservable();
  }

  setComponentData(componentName: string, type: string, data: any): void {
    if (!this.data[componentName]) {
      this.data[componentName] = {};
    }
    this.data[componentName][type] = data;
  }

  getComponentData(componentName: string, type: string): any {
    if (this.data[componentName] && this.data[componentName][type] !== undefined) {
      return this.data[componentName][type];
    }
    return null;
  }

  clearComponentData(componentName: string, type?: string): void {
    if (type) {
      if (this.data[componentName]) {
        delete this.data[componentName][type];
        if (Object.keys(this.data[componentName]).length === 0) {
          delete this.data[componentName];
        }
      }
    } else {
      delete this.data[componentName];
    }
  }

  convertToValueLabelWithAllArray(inputArray: any[], idKey: string, nameKey: string,data:boolean = false): any[] {
    let responseArray = [];
    if (nameKey.includes(',')) {      
      responseArray = inputArray.map(item => {
        let label = '';
        const keys = nameKey.split(',');
        for (const key in keys) {          
          label += `${item[keys[key]]} `
        }
        return {
          value: item[idKey],
          label: label,
          data:data ? item : {}
        }
      });      
    } else {
      responseArray = inputArray?.map(item => ({
        value: item[idKey],
        label: item[nameKey],
        data:data ? item : {}
      }));
    }
    return [{value: "All", label: "All", data: {}}].concat(responseArray);
  }

  convertToValueLabelArray(inputArray: any[], idKey: string, nameKey: string,data:boolean = false): any[] {
    if (nameKey.includes(',')) {      
      return inputArray.map(item => {
        let label = '';
        const keys = nameKey.split(',');
        for (const key in keys) {          
          label += `${item[keys[key]]} `
        }
        return {
          value: item[idKey],
          label: label,
          data:data ? item : {}
        }
      });      
    } else {
      return inputArray?.map(item => ({
        value: item[idKey],
        label: item[nameKey],
        data:data ? item : {}
      }));
    }    
  }  
  

  setUserAccess(data:any):void{
    this.userAccess.next(data);
  }

  getUserAccess():Observable<any>{
    return this.userAccess.asObservable();
  }

  setUserArtifacts(data:any):void{
    this.userArtifacts.next(data);
  }

  getUserArtifacts():Observable<any>{
    return this.userArtifacts.asObservable();
  }

  getArtifacts():Observable<any>{
    return this.get(`artifacts/`)
  }

  joinWorkPlace(): Observable<any> {
    return this.get(`joined/organization/?organization_id=${this.org()}`);
  }

  getOrganizations(all_result?: boolean): Observable<any> {
    let params = ``;
    if (all_result) {
      params += `?all_result=${all_result}`;
    }
    return this.get(`organizations/${params}`);
  }

  getCurrency(all_result?:boolean): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (all_result) {
        params += `&all_result=${all_result}`;
    }
    return this.get(`currencies/${params}`);
  }
  getCountries(all_result?:boolean): Observable<any> {
    let params = ``;
    if (all_result) {
        params += `?all_result=${all_result}`;
    }
    return this.get(`countries/${params}`);
  }

  getIndustries(abc?:any,xyz?:string,all_result?:boolean):Observable<any> {
    let params = ``;
    if (all_result) {
        params += `?all_result=${all_result}`;
    }
    return this.get(`industries/${params}`);
  }

  createOrganizations(body: any): Observable<any> {
    return this.post(`organizations/`, body);
  }

  OrganizationValidation(body: any): Observable<any> {
    return this.post(`organizations/validate_data/`, body);
  }

  retrieveOrganizations(id: any): Observable<any> {
    return this.get(`organizations/${id}/?organization_id=${this.org()}`);
  }

  updateOrganizations(id: any, body: any): Observable<any> {
    return this.put(`organizations/${id}/?organization_id=${this.org()}`, body);
  }

  deleteOrganizations(id: any): Observable<any> {
    return this.delete(`organizations/${id}/`);
  }

  businessStructure(all_result?:boolean): Observable<any> {
    let params = ``;
    if (all_result) {
        params += `?all_result=${all_result}`;
    }
    return this.get(`business-structure/${params}`);
  }

  getPagination(url:any, filterField?:any): Observable<any> {
    let params = ''
    if (filterField) {
      params += `&${filterField}`;
    }
    const newUrl = url?.replace(`http://`,`https://`) 
    return this.getCustomUrl(`${newUrl}${params}`);
  }

  isAnyKeyInvalid(list:any, key: any): boolean {
    return list.some((item:any) => item[key] === undefined || item[key] === '' || item[key] === null);
  }

  updateParams(queryParams: any, sortingParams: any): any {
    queryParams?.keys()?.forEach((key:any) => {
      if (!sortingParams?.has(key) && queryParams?.has(key)) {
        sortingParams = sortingParams?.append(key, queryParams?.get(key));
        }
    });
    return sortingParams;
}
getDepartments(all_result?: boolean): Observable<any> {
  const organizationId = localStorage.getItem('Org_Id');
  let params = `?organization_id=${organizationId}`;
  if (all_result) {
    params += `&all_result=${all_result}`;
  }
  return this.get(`organizations/departments/${params}`);
}

  getDesignations(all_result?: boolean): Observable<any> {
    const organizationId = localStorage.getItem('Org_Id');
    let params = `?organization_id=${organizationId}`;
    if (all_result !== undefined) {
      params += `&all_result=${all_result}`;
    }
    return this.get(`organizations/designations/${params}`);
  }

  getWorkAddress(all_result?:boolean): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (all_result) {
        params += `&all_result=${all_result}`;
    }
    return this.get(`organizations/work-address/${params}`);
  }

  // members
  getMembers(filterField?: string, ordering?: any,all_result? : Boolean,searchText?: any,type?: any, status?: any, limited_fields?: any,): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (filterField) {
      params += `&${filterField}`;
    }
    if (ordering) {
      params += `&ordering=${ordering}`;
    }
     if (searchText) {
    params += `&search=${searchText}`;
    }
    if (all_result) {
        params += `&all_result=${all_result}`;
    }
    if (limited_fields) {
      params += `&limited_fields=${limited_fields}`;
    }    
    if (type) {
      params += `&${type}`;
    }
    if (status) {
      params += `&${status}`;
    }
    return this.get(`organizations/members/${params}`);
  }

  updateMember(body: any, id: Number): Observable<any> {
      return this.put(`organizations/members/${id}/?organization_id=${this.org()}`, body);
  }

  deleteMember(id: Number): Observable<any> {
      return this.delete(`organizations/members/${id}/?organization_id=${this.org()}`);
  }

  retrieveMember(id:number):Observable<any>{
      return this.get(`organizations/members/${id}/?organization_id=${this.org()}`)
  }

  createMember(body: any, id?:any): Observable<any> {
    const ID:any = this.org() ? this.org() : id;   
    return this.post(`organizations/members/?organization_id=${ID}`, body);
  }

  createVerifivationemail(body: any ,id?: any): Observable<any> {
    const ID:any = this.org() ? this.org() : id;   
    return this.post(`organizations/members/resend-verification-email/?organization_id=${ID}`, body);    
  }

  // payslip

  createPaySlip(body: any): Observable<any> {
    return this.post(`member-salary-logs/?organization_id=${this.org()}`, body);
  }

  updatePaySlip(body: any, id:any): Observable<any> {
    return this.put(`member-salary-logs/${id}/?organization_id=${this.org()}`, body);
  }  

  retrievePaySlip(id:number):Observable<any>{
    return this.get(`member-salary-logs/${id}/?organization_id=${this.org()}`)
}

  getPaySlip(memberId: any, all_result? : Boolean, is_payslip_generated?: Boolean): Observable<any> {
    let params = `organization_id=${this.org()}`;
    if (all_result) {
        params += `&all_result=${all_result}`;
    }
    if (memberId) {
      params += `&member_id=${memberId}`;
    }
    if (is_payslip_generated) {
      params += `&is_payslip_generated=${is_payslip_generated}`;
    }
    return this.get(`member-salary-logs/?${params}`);
  }

  getPaySlipData(body: any): Observable<any> {
    return this.post(`member-salary-logs/get-payslip-data/?organization_id=${this.org()}`, body);    
  }

  getMonthWorkingDays(body: any): Observable<any> {
    return this.post(`member-salary-logs/month-working-days/?organization_id=${this.org()}`, body);    
  }  

  //team-structure

  getTeamstructure(all_result? : Boolean): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (all_result) {
        params += `&all_result=${all_result}`;
    }
    return this.get(`organization-tree/${params}`);
  }

  //userProfile

  getProfile(): Observable<any> {
    return this.get(`user-profile/?organization_id=${this.org()}`);
  }

  updateProfile(body: any, id: Number): Observable<any> {
    return this.put(`user-profile/${id}/?organization_id=${this.org()}`, body);
  }

  getProfileSalary(): Observable<any> {
    return this.get(`profile-salary/?organization_id=${this.org()}`);
  }

  getProfilesalarylogs(): Observable<any> {
    return this.get(`member-salary-logs/profile/?organization_id=${this.org()}`);
  }

  //Notification
  registerDevice(body: any): Observable<any> {
    return this.post(`fcm/register/device/`, body);
}

  //Document

deleteDocument(id: Number): Observable<any> {
    return this.delete(`organizations/documents/${id}/?organization_id=${this.org()}`);
}

getdocument(id:number,ordering?: any):Observable<any>{
  let params = `?organization_id=${this.org()}`;
  if (ordering) {
    params += `&${ordering}`;
  }
  return this.get(`organizations/documents/${params}`)
}

documentUpload(body: any, id?:any): Observable<any> {
  const ID:any = this.org() ? this.org() : id;   
  return this.post(`organizations/documents/?organization_id=${this.org()}`, body);
}

  getattendanceData(memberId?: any, startdate?: any, endDate?: any): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (memberId) {
      params += `&member_id=${memberId}`;
    }
    if (startdate) {
      params += `&start_date=${startdate}`;
    }
    if (endDate) {
      params += `&end_date=${endDate}`;
    }
    return this.get(`attendance-report/${params}`);
  }

  getMemberreportExcel( memberId?: any, startdate?: any, endDate?: any): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (memberId) {
      params += `&member_id=${memberId}`;
    }
    if (startdate) {
      params += `&start_date=${startdate}`;
    }
    if (endDate) {
      params += `&end_date=${endDate}`;
    }
    return this.getExcel(`attendance-report/excel/${params}`)
  }

  getleaveData(memberId?: any, startdate?: any, endDate?: any): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (memberId) {
      params += `&member_id=${memberId}`;
    }
    if (startdate) {
      params += `&start_date=${startdate}`;
    }
    if (endDate) {
      params += `&end_date=${endDate}`;
    }
    return this.get(`leave-report/${params}`);
  }

  getMemberReportExcel( memberId?: any, startdate?: any, endDate?: any): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (memberId) {
      params += `&member_id=${memberId}`;
    }
    if (startdate) {
      params += `&start_date=${startdate}`;
    }
    if (endDate) {
      params += `&end_date=${endDate}`;
    }
    return this.getExcel(`reports/members/leave-summary-report/excel/${params}`)
  }

  // Asset
  getAsset(filterField?: string,all_result? : Boolean, searchText?: any, ordering?: any): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (filterField) {
      params += `&${filterField}`;
    }
    if (all_result) {
      params += `&all_result=${all_result}`;
    }
    if (searchText) {
      params += `&search=${searchText}`;
    }
    if (ordering) {
      params += `&${ordering}`;
    }
    return this.get(`asset/${params}`)
  }

  retrieveAsset(id: any): Observable<any> {
    return this.get(`asset/${id}/?organization_id=${this.org()}`);
  }
  createAsset(body: any): Observable<any> {
    return this.post(`asset/?organization_id=${this.org()}`, body);
  }

  updateAsset(id: any, body: any,assetId: any): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (assetId) {
      params += `&project_id=${assetId}`;
    }
    return this.put(`asset/${id}/${params}`, body);
  }

  //Assets Assignment

  listAssetAssignment(memberId?: any,all_result? : Boolean, assetId?: any): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (memberId) {
      params += `&member_id=${memberId}`;
    }
    if (all_result) {
      params += `&all_result=${all_result}`;
    }
    if (assetId) {
      params += `&asset_id=${assetId}`;
    }
    return this.get(`asset/assignment/${params}`)
  }

  createAssetAssignment(body: any): Observable<any> {
    return this.post(`asset/assignment/?organization_id=${this.org()}`, body);
  }

  createAcknowledgement(body: any): Observable<any> {
    return this.post(`asset/assignment-acknowledgement/?organization_id=${this.org()}`, body);
  }

  updateAcknowledgement(id: any, body: any): Observable<any> {
    return this.put(`asset/assignment/${id}/?organization_id=${this.org()}`, body);
  }

  //request

  listRequest(all_result? : Boolean,type?: any): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (all_result) {
      params += `&all_result=${all_result}`;
  }
  if (type) {
    params += `&${type}`;
}
    return this.get(`asset/assignment/${params}`)
  }

  //branch
  
  getBranch(filterField?: string,all_result? : boolean,searchText?: any,ordering?: any): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (filterField) {
      params += `&branch_type=${filterField}`;
    }
    if (all_result) {
      params += `&all_result=${all_result}`;
    }
    if (searchText) {
      params += `&search=${searchText}`;
      }
   
    if (ordering) {
      params += `&${ordering}`;
    }
    return this.get(`organizations/branch/${params}`);
  }
 




  updateBranch(body: any, id: Number): Observable<any> {
      return this.put(`organizations/branch/${id}/?organization_id=${this.org()}`, body);
  }

  createBranch(body: any): Observable<any> {
   return this.post(`organizations/branch/?organization_id=${this.org()}`, body);
  }

  // expense
  getexpense(filterField?: string, ordering?: any, all_result? : Boolean,searchText?: any,type?: any, status?: any): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (filterField) {
      params += `&${filterField}`;
    }
    if (all_result) {
      params += `&all_result=${all_result}`;
    }
    if (ordering) {
      params += `&ordering=${ordering}`;
    }
    if (searchText) {
      params += `&search=${searchText}`;
    }
    if (type) {
      params += `&${type}`;
    }
    if (status) {
      params += `&${status}`;
    }
    return this.get(`expense/${params}`);
  }

  addexpense(body: any, id?:any): Observable<any> {
    return this.post(`expense/?organization_id=${this.org()}`, body);
  }

  updateexpense(body: any, id: Number,type: any): Observable<any> {
    return this.put(`expense/${id}/?organization_id=${this.org()}&type=${type}`, body);
  }

  deleteexpense(id: Number): Observable<any> {
    return this.delete(`expense/${id}/?organization_id=${this.org()}`);
  }

  getexpenseType(all_result?:boolean): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (all_result) {
        params += `&all_result=${all_result}`;
    }
    return this.get(`expense/type/${params}`);
  }

  getexpensecategory(all_result?:boolean): Observable<any> {
    let params = `?organization_id=${this.org()}`;
    if (all_result) {
        params += `&all_result=${all_result}`;
    }
    return this.get(`expense/category/${params}`);
  }

  // local time zone

  getLocalTimezone(): any {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const offset = -new Date().getTimezoneOffset();
    const hours = Math.floor(offset / 60);
    const minutes = Math.abs(offset % 60);
    const sign = hours >= 0 ? '+' : '-';
    const gmtOffset = `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    return {
      city: timezone,
      GMT: gmtOffset
    }
  }
}