<ng-template #modal>
  <div class="modal-content">

    <div id="printModalHeader" class="modal-header" *ngIf="modalConfig.hideHeader === undefined || !modalConfig.hideHeader()">
      <!--begin::Modal title-->
      <h4>{{ modalConfig.modalTitle }}</h4> 
      <div class="btn btn-sm btn-icon btn-active-color-primary" (click)="close()"
        *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()">
        <span class="svg-icon svg-icon-1" [inlineSVG]="'./assets/media/icons/duotune/arrows/arr061.svg'"></span>
        <!--end::Svg Icon-->
      </div>
    </div>

    <div class="modal-body scroll-y py-2">
      <ng-content></ng-content>
    </div>

    <div id="printModalFooter" class="modal-footer justify-content-between" *ngIf="modalConfig.hideFooter === undefined || !modalConfig.hideFooter()">
      <button
        type="reset"
        class="btn btn-secondary btn-sm mx-3"
        data-kt-menu-dismiss="true"
        (click)="close()"
        *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
        [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()">
      
        {{ modalConfig.closeButtonLabel }}
      </button>
      <button
        type="submit"
        class="btn btn-sm btn-primary align-self-center cursor-pointer"
        data-kt-menu-dismiss="true"
        (click)="dismiss()"
        *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()"
      [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()"
      >
          {{ modalConfig.dismissButtonLabel }}
      </button>
    </div>
  </div>
</ng-template>