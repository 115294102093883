import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ConnectBaseService } from 'src/app/_metronic/kt/_utils/base.service';


const API_USERS_URL = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})
export class IntegrationService extends ConnectBaseService {
    getIntegrationTypes(custom?: any) {
        let params = `?organization_id=${this.org()}`;
        if (custom) {
            params += `&${custom}`;
        }
        return this.get(`integration/${params}`);
    }

    getIntegrations() {
        return this.get(`integrations/?organization_id=${this.org()}`);
    }

    setMicrosoft() {
        return this.get(`microsoft-login/?organization_id=${this.org()}`)
    }

    revokeMicrosoft(body:any) {
        return this.post(`microsoft-revoke/?organization_id=${this.org()}`,body)
    }

    updateMicrosoft(body:any) {
        return this.post(`microsoft-login-code/?organization_id=${this.org()}`,body)
    }

    createIntegration(body: any): Observable<any> {
        return this.post(`organization-integration/?organization_id=${this.org()}`, body);
    }

    updateIntegration(body: any, id: any): Observable<any> {
        return this.put(`integrations/${id}/?organization_id=${this.org()}`, body);
    }

    getCalendarURL() {
        return this.get(`get-calendar-url/?organization_id=${this.org()}`);
    }

    getBiometricServers(all_result: any = null) {
        let params = `?organization_id=${this.org()}`;
        if (all_result) {
            params += '&all_result=true';
        }
        return this.get(`biometric-servers/${params}`);
    }
}
