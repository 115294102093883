<ng-container *ngIf="user$ | async as _user">
  <div class="menu-item px-4">
    <div class="menu-content">
      <div class="d-flex flex-column">
        <div class="fw-bolder d-flex align-items-center fs-5">
          {{ _user?.first_name }} {{ _user?.last_name }}
        </div>
        <a class="fw-bold text-muted text-hover-primary fs-8 cursor-pointer">
          {{ _user.email }}
        </a>
      </div>
    </div>
  </div>

  <div class="separator"*ngIf="isview"></div>

  <div class="menu-item px-5" *ngIf="isview">
    <a (click)="navigate()" class="menu-link">
      My Profile
    </a>
  </div>

  <div class="separator"></div>

  <div class="menu-item px-5" *ngIf="isview">
    <a (click)="resetPassword()" class="menu-link">
      Reset Password
    </a>
  </div>

  <div class="separator"></div>
  <div class="menu-item px-5" *ngIf="isview">
    <a target="_blank" [href]="appPreviewDocsUrl" class="menu-link">
      Docs & Components
    </a>
  </div>
  <div class="separator"></div>
  <div class="menu-item px-5">
    <a (click)="logout()" class="menu-link cursor-pointer"> Sign Out </a>
  </div>
</ng-container>
