import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import { finalize, interval, Observable, of, switchMap, take, tap} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  retryCount: number = 3;
  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkCurrentUser().pipe(
      finalize(() => {
        if (!this.authService.currentUserValue) {
          this.authService.logout();
        }
      })
    );
  }


  private checkCurrentUser(): Observable<boolean> {
    return interval(1000).pipe(
      switchMap(() => of(this.authService.currentUserValue)),
      tap((currentUser) => {
        if (!currentUser) {
          console.log('User not found, retrying...');
        }
      }),
      take(this.retryCount),
      switchMap((currentUser) => {
        if (currentUser) {
          return of(true);
        }

        return of(false);
      })
    );
  }
}
