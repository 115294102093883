import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { Select2Module } from 'ng-select2-component';
import { OrganizationService } from 'src/app/modules/common/services/organization.service';

@Component({
  selector: 'app-quotation-supplier',
  templateUrl: './quotation-supplier.component.html',
  styleUrls: ['./quotation-supplier.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, Select2Module, InlineSVGModule]
})
export class QuotationSupplierComponent  implements OnInit {

  overlay = false;
  form: FormGroup;
  listMember: any;
  newMemberList: any[] = [];

  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.initForm();
    this.getMember();
  }

  initForm() {
    this.form = this.fb.group({
      suppliers: [null, Validators.required]
    });
  }

  getMember() {
    this.organizationService.getMembers(undefined, undefined, true).subscribe({
      next: (val) => {
        this.listMember = val.results;
        this.newMemberList = this.organizationService.convertToValueLabelArray(this.listMember, 'id', 'first_name,last_name');
        this.cdr.detectChanges();
      },
      error: (err) => {
      }, complete: () => {
      }
    });
  }

  createSupplier(): FormGroup {
    return this.fb.group({
      suppliers: [null, Validators.required]
    });
  }

  get suppliers(): FormArray {
    return this.form.get('suppliers') as FormArray;
  }

  addSupplier() {
    this.suppliers.push(this.createSupplier());
  }

  removeSupplier(index: number) {
    if (this.suppliers.length > 1) {
      this.suppliers.removeAt(index);
    }
  }

  emitFormData() {
    return this.form.value;
  }
  
}
