import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConnectBaseService } from 'src/app/_metronic/kt/_utils/base.service';


@Injectable({
    providedIn: 'root',
})
export class APIService extends ConnectBaseService {

    updateParams(queryParams: any, sortingParams: any): any {
        if (sortingParams) {
            queryParams?.keys()?.forEach((key: any) => {
                if (!sortingParams.has(key) && queryParams.has(key)) {
                    sortingParams = sortingParams.append(key, queryParams.get(key));
                }
            });
            return sortingParams;
        }
        return queryParams;
    }

      //For Post API Pagination

      paginationPost(url: any, body?: any, param?: any , excludeDomain:boolean = false): Observable<any> {
        let params = excludeDomain ? '': '';
        if (param) {
            params += `&${param}`
        }
        return this.post(`${url}${params}`, body,excludeDomain);
    }

    createItem(url: any, body?: any, param?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (param) {
            params += `&${param}`
        }
        return this.post(`${url}/${params}`, body);
    }

    Excel(url: any, body?: any, params?: any): Observable<any> {
        let query_params = `?organization_id=${this.org()}`;
        // if (param) {
        //     params += `&${param}`
        // }
        body = Object.assign({}, body, params);
        return this.postExcel(`${url}/${query_params}`, body);
    }

    listItem(url: any, param?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (param) {
            params += `&${param}`
        }
        return this.get(`${url}/${params}`);
    }

    customecreateItem(url: any, body: any, param?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (param) {
            params += `&${param}`
        }
        return this.postCustomUrl(`${url}/${params}`, body);
    }

    customeListItem(url: any, param?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (param) {
            params += `&${param}`
        }
        return this.getCustomUrl(`${url}/${params}`);
    }

    retrieveItem(url: any, id: any, param?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (param) {
            params += `&${param}`
        }
        return this.get(`${url}/${id}/${params}`);
    }

    updateItem(url: any, body: any, id: any, param?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (param) {
            params += `&${param}`
        }
        return this.put(`${url}/${id}/${params}`, body);
    }

    patchItem(url: any, body: any, id: any, param?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (param) {
            params += `&${param}`
        }
        return this.patch(`${url}/${id}/${params}`, body);
    }

    deleteItem(url: any, id: any, param?: any): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (param) {
            params += `&${param}`
        }
        return this.delete(`${url}/${id}/${params}`);
    }
}