import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PageInfoService, PageLink } from 'src/app/_metronic/layout/core/page-info.service';
import { OrganizationService } from '../../../../../modules/common/services/organization.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/modules/auth';
import { OrganizationHandlerUtil } from 'src/app/_metronic/kt/_utils/organizationUtil';
import { RoleService } from 'src/app/modules/common/services/role.service';

@Component({
  selector: 'app-dropdown-menu2',
  templateUrl: './dropdown-menu2.component.html',
})
export class DropdownMenu2Component implements OnInit {
  [x: string]: any;
  @HostBinding('class') class =
    'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px';
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';

  bc$: Observable<Array<PageLink>>;
  orgDropdown: boolean = false;
  org: any;
  id: any;
  name: any;

  constructor(private pageInfo: PageInfoService,
    private router: Router,
    private organizationService: OrganizationService,
    private roleService: RoleService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,) { }


  ngOnInit(): void {
    this.bc$ = this.pageInfo.breadcrumbs.asObservable();
    this.getModulename();
    this.getOrglist();
  }
  getModulename() {
    this.bc$.subscribe((val) => {
      if (val[0]?.title === 'Profile') {
        this.orgDropdown = true;
      }
    })
  }

  getArtifacts(organization:any) {

    this.roleId = organization.user_role;
    if (this.roleId == "ADMIN") {
      this.roleService.getArtifacts().subscribe((data:any) => {
        this.artiFacts = data.artifacts;
        this.organizationService.setArtifactsValue(this.artiFacts);
        localStorage.setItem('artiFacts', JSON.stringify(this.artiFacts));
        this.cdr.detectChanges();
      });
    } else if (this.roleId == null) {

      this.router.navigateByUrl(`/error/404`)
    } else {
      this.roleService.getRoleRetrieve(this.roleId).subscribe((data:any) => {
        this.artiFacts = data.data.artifacts;
        this.organizationService.setArtifactsValue(this.artiFacts);
        localStorage.setItem('artiFacts', JSON.stringify(this.artiFacts));
        this.cdr.detectChanges();
      });

    }
  }

  orgName(organization: any, addNew: boolean = false, dropdown: boolean = false) {
    if (addNew) {

      this.selectedOrgId = "add_new_organization";
      // localStorage.setItem('Org_Name', 'Add New Organization');
      this.trigger();
      const currentProtocol = window.location.protocol;
      const currentHost = window.location.host;
      const updatedHost = currentHost.replace(/^[^.]+/, 'app');
      window.open(`${currentProtocol}//${updatedHost}/organization/add`, '_blank');
    } else {
      if (organization) {
        this.selectedOrgId = organization.id;
        if (localStorage.getItem('Org_Id') === null) {
          localStorage.setItem('Org_Id', this.id);
          localStorage.setItem('Org_Name', this.name);
          localStorage.setItem('organization', JSON.stringify(organization));
          this.getArtifacts(organization)
          this.trigger();
          this.joinWorkPlace()
          OrganizationHandlerUtil.goToOrgWorkSpace(organization.id, organization.subdomain, '_self', dropdown);
        } else {
          localStorage.setItem('Org_Id', organization.id);
          localStorage.setItem('Org_Name', organization.name);
          localStorage.setItem('organization', JSON.stringify(organization));
          this.getArtifacts(organization)
          this.trigger()
          this.joinWorkPlace()
          OrganizationHandlerUtil.goToOrgWorkSpace(organization.id, organization.subdomain, '_self', dropdown);
        } 
      }
    }
  }

  trigger() {
    this.organizationService.triggerEventOrg();
  }

  joinWorkPlace(){
    this.organizationService.joinWorkPlace().subscribe({
      next(value) {},
      error(err) {}
    })
  }

  getOrglist() {
    this.organizationService.getOrganizations(true).subscribe({
      next: (value) => {
        this.org = value.results;
      }, error(err) {

      }, complete: () => {
        this.getZeroIndexId(this.org);
        const hostname = window.location.hostname;
        const domainParts = hostname.split('.')

        if (domainParts?.length > 0) {
          if (domainParts[0] === 'app') {
            this.orgName(this.org[0], false);
          } else {
            const item = this.org?.find((s: any) => s.subdomain === domainParts[0])
            this.orgName(item, false);
          }
        }
        this.cdr.detectChanges();
      },
    });
  }

  getZeroIndexId(arr: any[]): any {
    const subdomain = window.location.host.split('.')[0];
    if (arr.length > 0) {
      const requestedOrg = arr.filter((org) => org.subdomain == subdomain);
      if (requestedOrg.length > 0) {
        this.name = requestedOrg[0].name;
        this.id = requestedOrg[0].id;
      } else {
        this.name = arr[0].name;
        this.id = arr[0].id;
        // OrganizationHandlerUtil.goToOrgWorkSpace(this.id, arr[0].subdomain, '_self');
      }
      return this.id;
    } else {
      return null;
    }
  }

  getObjectById(arr: any[], id: number): any {
    return arr.find((item:any) => item.id == id);
  }


}
