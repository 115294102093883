<!-- Dashboard -->
<div class="menu-item">
  <a
    class="menu-link without-sub"
    routerLink="/dashboard"
    routerLinkActive="active"
    ><span class="menu-icon">
      <span
        [inlineSVG]="'./assets/media/icons/duotune/art/art002.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </span>
    <span class="menu-title" translate="MENU.DASHBOARD"></span
  ></a>
</div>

<!-- Layout builder -->
<div class="menu-item">
  <a
    class="menu-link without-sub"
    routerLink="/builder"
    routerLinkActive="active"
    ><span class="menu-icon">
      <span
        [inlineSVG]="'./assets/media/icons/duotune/general/gen019.svg'"
        class="svg-icon svg-icon-2"
      ></span> </span
    ><span class="menu-title">Layout Builder</span></a
  >
</div>
<!-- Separator -->
<div class="menu-item">
  <div class="menu-content pt-3 pb-1">
    <span class="menu-section text-muted text-uppercase fs-8 ls-1"
      >Crafted</span
    >
  </div>
</div>

<!-- Pages -->
<div
  class="menu-item menu-accordion"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link"
    ><span class="menu-icon"
      ><span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/general/gen022.svg'"
      ></span></span
    ><span class="menu-title">Pages</span><span class="menu-arrow"></span
  ></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div
      class="menu-item menu-accordion"
      routerLinkActive="here show"
      data-kt-menu-trigger="click"
    >
      <span class="menu-link"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title" data-link="/crafted/pages/profile"
          >Profile</span
        ><span class="menu-arrow"></span
      ></span>
      <div
        class="menu-sub menu-sub-accordion"
        routerLinkActive="menu-active-bg"
      >
        <div class="menu-item">
          <a
            class="menu-link without-sub"
            routerLinkActive="active"
            routerLink="/crafted/pages/profile/overview"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Overview</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link without-sub"
            routerLinkActive="active"
            routerLink="/crafted/pages/profile/projects"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Projects</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link without-sub"
            routerLinkActive="active"
            routerLink="/crafted/pages/profile/campaigns"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Campaigns</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link without-sub"
            routerLinkActive="active"
            routerLink="/crafted/pages/profile/documents"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Documents</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link without-sub"
            routerLinkActive="active"
            routerLink="/crafted/pages/profile/connections"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Connections</span></a
          >
        </div>
      </div>
    </div>
    <div
      class="menu-item menu-accordion"
      routerLinkActive="here show"
      data-kt-menu-trigger="click"
    >
      <span class="menu-link"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title" data-link="/crafted/pages/wizards"
          >Wizards</span
        ><span class="menu-arrow"></span
      ></span>
      <div
        class="menu-sub menu-sub-accordion"
        routerLinkActive="menu-active-bg"
      >
        <div class="menu-item">
          <a
            class="menu-link without-sub"
            routerLinkActive="active"
            routerLink="/crafted/pages/wizards/horizontal"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Horizontal</span></a
          >
        </div>
        <div class="menu-item">
          <a
            class="menu-link without-sub"
            routerLinkActive="active"
            routerLink="/crafted/pages/wizards/vertical"
            ><span class="menu-bullet"
              ><span class="bullet bullet-dot"></span></span
            ><span class="menu-title">Vertical</span></a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Accounts -->
<div
  class="menu-item menu-accordion"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link"
    ><span class="menu-icon"
      ><span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/communication/com006.svg'"
      ></span></span
    ><span class="menu-title" data-link="/crafted/account">Accounts</span
    ><span class="menu-arrow"></span
  ></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/account/overview"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Overview</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/account/settings"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Settings</span></a
      >
    </div>
  </div>
</div>

<!-- Errors -->
<div
  class="menu-item menu-accordion"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link"
    ><span class="menu-icon"
      ><span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/general/gen040.svg'"
      ></span></span
    ><span class="menu-title" data-link="/error">Errors</span
    ><span class="menu-arrow"></span
  ></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLink="/error/404"
        routerLinkActive="active"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Error 404</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLink="/error/500"
        routerLinkActive="active"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Error 500</span></a
      >
    </div>
  </div>
</div>

<!-- Widgets -->
<div
  class="menu-item menu-accordion"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link"
    ><span class="menu-icon"
      ><span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/general/gen025.svg'"
      ></span></span
    ><span class="menu-title" data-link="/crafted/widgets">Widgets</span
    ><span class="menu-arrow"></span
  ></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/widgets/lists"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Lists</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/widgets/statistics"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Statistics</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/widgets/charts"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Charts</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/widgets/mixed"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Mixed</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/widgets/tables"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Tables</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/crafted/widgets/feeds"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Feeds</span></a
      >
    </div>
  </div>
</div>

<!-- Separator -->
<div class="menu-item">
  <div class="menu-content pt-3 pb-1">
    <span class="menu-section text-muted text-uppercase fs-8 ls-1">Apps</span>
  </div>
</div>

<!-- Chat -->
<div
  class="menu-item menu-accordion"
  data-kt-menu-trigger="click"
  routerLinkActive="here show"
>
  <span class="menu-link"
    ><span class="menu-icon"
      ><span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/communication/com012.svg'"
      ></span></span
    ><span class="menu-title" data-link="/apps/chat">Chat</span
    ><span class="menu-arrow"></span
  ></span>
  <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/apps/chat/private-chat"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Private Chat</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/apps/chat/group-chat"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Group Chart</span></a
      >
    </div>
    <div class="menu-item">
      <a
        class="menu-link without-sub"
        routerLinkActive="active"
        routerLink="/apps/chat/drawer-chat"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title">Drawer Chart</span></a
      >
    </div>
  </div>
</div>

<!-- Separator -->
<div class="menu-item">
  <div class="menu-content">
    <div class="separator mx-1 my-4"></div>
  </div>
</div>

<!-- Changelog  -->
<div class="menu-item">
  <a target="_blank" class="menu-link">
    <span class="menu-icon">
      <span
        class="svg-icon svg-icon-2"
        [inlineSVG]="'./assets/media/icons/duotune/general/gen005.svg'"
      ></span>
    </span>
    <span class="menu-title">Changelog {{ appAngularVersion }}</span>
  </a>
</div>
