<!--begin::Input group-->
<nav class="navbar navbar-expand-lg navbar-light">
    <ul class="navbar-nav mr-auto">
      <mat-form-field class="me-lg-1 pt-5" style="">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date"[(ngModel)]="startDate" (input)="updateDateRange()" (ngModelChange)="logDateRange('startdate','enddate')">
          <input matEndDate placeholder="End date"[(ngModel)]="endDate" (input)="updateDateRange()"(ngModelChange)="logDateRange('enddate','startdate')">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <!-- <li class="nav-item">
        <a class="nav-link required">Start Date</a>
        <input
          type="date"
          class="form-control mr-sm-2"
          [(ngModel)]="startDate"
          name="startDate"
          (input)="updateDateRange()"
          required
        />
        <div *ngIf="!startDate && startDateTouched">
          Start Date is required.
        </div>
      </li>
      &nbsp; -->
      <!-- <li class="nav-item">
        <a class="nav-link required">End Date</a>
        <input
          type="date"
          class="form-control mr-sm-2"
          [(ngModel)]="endDate"
          name="endDate"
          (input)="updateDateRange()"
          required
        />
        <div *ngIf="!endDate && endDateTouched">
          End Date is required.
        </div>
      </li>
      &nbsp; -->
      <!-- <li class="nav-item me-lg-1">
        <select2 [overlay]="overlay" (update)="update('filter', $event,'filter')" [value]="filterValue"  multiple="true" class="w-250px form-control mr-sm-2" [data]="newstatus1" >
        </select2>
      </li> -->
    </ul>
</nav>
<!--end::Input group-->


<!--end::Toolbar start-->
<div class="d-flex flex-shrink-0 ">
  <!--begin::Button-->

  <div class="d-flex d-none align-items-center gap-2 gap-lg-3">
    <!--begin::Filter menu-->
    <div class="ms-3">
      <!--begin::Menu toggle-->
      <a  class="btn btn-sm btn-icon btn-active-color-success" (click)="action('Add')"  data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen035.svg'" class="svg-icon svg-icon-2x"></span>
      </a>
      <!--end::Menu toggle-->
      <!--begin::Menu 1-->
      <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_63de6be01975d">
        <!--begin::Header-->
        <div class="px-7 py-5">
          <div class="fs-5 text-dark fw-bold">Filter</div>
        </div>
        <!--end::Header-->
        <!--begin::Menu separator-->
        <div class="separator border-gray-200"></div>
        <!--end::Menu separator-->
        <!--begin::Form-->
        <div class="px-7 py-5">
          <!--begin::Input group-->
          <div class="mb-2">
            <!--begin::Label-->
            <label class="form-label fw-semibold">Status:</label>
            <!--end::Label-->
            <!--begin::Input-->
            <div>
              <select class="form-select form-select-solid" data-kt-select2="true" data-placeholder="Select option" data-dropdown-parent="#kt_menu_63de6be01975d" data-allow-clear="true">
                <option disabled selected>Please Select</option>
                <option value="1">Hiring</option>
                <option value="2">Attendance</option>
                <option value="2">Salary</option>
                <option value="2">Request</option>
              </select>
            </div>
            <!--end::Input-->
          </div>
          <!--end::Input group-->
          <!--begin::Input group-->
          <div class="mb-5">
            <!--begin::Label-->
            <label class="form-label fw-semibold">Ui Type:</label>
            <!--end::Label-->
            <!--begin::Options-->
            <div class="d-flex flex-column h-100px overflow-auto">
              <!--begin::Options-->
              <label *ngFor="let list of listWidget" class="form-check form-check-sm form-check-custom form-check-solid me-5 mb-3">
                <input class="form-check-input" type="checkbox" [checked]="isChecked(list.is_view)" value="1" (change)="onCheckboxChange(list)"  />
                <span class="form-check-label">{{list.name}}</span>
              </label>
              <!--end::Options-->
            </div>
            <!--end::Options-->
          </div>
          <!--end::Input group-->
          <!--begin::Actions-->
          <div class="d-flex justify-content-end">
            <button type="reset" class="btn btn-sm btn-light btn-active-light-secondry me-2" data-kt-menu-dismiss="true">Reset</button>
            <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Form-->
      </div>
      <!--end::Menu 1-->
    </div>
  </div>
  <!--end::Button-->
  <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Pin">
    <a  class="btn btn-sm btn-icon btn-active-color-success d-none" (click)="action('Pin')">
      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen047.svg'" class="svg-icon svg-icon-2x"></span>
    </a>
  </div>
  <div data-bs-toggle="tooltip" data-bs-placement="top"  data-bs-trigger="hover" title="Reload">
    <a  class="btn btn-sm btn-icon btn-active-color-success d-none" (click)="action('Reload')">
      <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr029.svg'" class="svg-icon svg-icon-2x"></span>
    </a>
  </div>
  <!--begin::Button-->
  <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Delete">
    <a  class="btn btn-sm btn-icon btn-active-color-success d-none" (click)="action('Delete')">
      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'" class="svg-icon svg-icon-2x"></span>
    </a>
  </div>
  <!--end::Button-->
</div>
<!--end::Actions-->
<!--begin::Toolbar end-->
<!-- <div class="d-flex align-items-center"> -->
  <!--begin::Input group-->
  <!-- <div class="me-3"> -->
    <!--begin::Select-->
    <!-- <select class="form-select form-select-sm form-select-solid" data-control="select2" data-placeholder="Latest"
      data-hide-search="true">
      <option value=""></option>
      <option value="1" selected>Today 16 Feb</option>
      <option value="2">In Progress</option>
      <option value="3">Done</option>
    </select> -->
    <!--end::Select-->
  <!-- </div> -->
  <!--end::Input group--->

  <!--begin::Input group--->
  <!-- <div class="m-0">
    
    <a class="btn btn-sm fw-bold btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">Submit</a>
  </div> -->
  <!--end::Input group--->
<!-- </div> -->
