<!-- begin::Beader -->
<div class="card-header border-0 py-5">
  <h4 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1">Action Needed</span>
    <span class="text-muted fw-bold fs-7">Complete your profile setup</span>
  </h4> 

  <div class="card-toolbar">
    <!-- begin::Menu -->
    <button
      type="button"
      class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <span
        [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </button>
    <app-dropdown-menu1></app-dropdown-menu1>
    <!-- end::Menu -->
  </div>
</div>
<!-- end::Header -->

<!-- begin::Body -->
<div class="card-body d-flex flex-column">
  <div class="flex-grow-1">
    <div #chartRef class="mixed-widget-4-chart" [style.height]="chartHeight">
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [dataLabels]="chartOptions.dataLabels"
        [stroke]="chartOptions.stroke"
        [legend]="chartOptions.legend"
        [fill]="chartOptions.fill"
        [states]="chartOptions.states"
        [tooltip]="chartOptions.tooltip"
        [colors]="chartOptions.colors"
        [markers]="chartOptions.markers"
        [plotOptions]="chartOptions.plotOptions"
      ></apx-chart>
    </div>
  </div>

  <div class="pt-5">
    <p class="text-center fs-6 pb-5">
      <span class="badge badge-light-danger fs-8">Notes:</span>&nbsp; Current
      sprint requires stakeholders
      <br />
      to approve newly amended policies
    </p>

    <a href="#" class="btn w-100 py-3" [ngClass]="'btn-' + chartColor">
      Take Action
    </a>
  </div>
</div>
<!-- end::Body -->
