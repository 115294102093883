import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tables-widget16',
  templateUrl: './tables-widget16.component.html',
  styleUrls: ['./tables-widget16.component.scss']
})
export class TablesWidget16Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
