import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToWords'
})
export class NumberToWordsPipe implements PipeTransform {
  transform(value: number): string {
    if (value === 0) return 'Zero';

    const ones = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const scales = ['', 'Thousand', 'Million', 'Billion'];

    // Function to convert integer part to words
    const integerToWords = (num: number): string => {
      if (num === 0) return '';
      if (num < 10) return ones[num - 1];
      if (num === 10) return tens[0];
      if (num < 20) return teens[num - 11];
      if (num < 100) return tens[Math.floor(num / 10) - 1] + ' ' + integerToWords(num % 10);
      if (num < 1000) return ones[Math.floor(num / 100) - 1] + ' Hundred ' + integerToWords(num % 100);
      for (let i = 0; i < scales.length; i++) {
        if (num < Math.pow(1000, i + 1)) {
          return integerToWords(Math.floor(num / Math.pow(1000, i))) + ' ' + scales[i] + ' ' + integerToWords(num % Math.pow(1000, i));
        }
      }
      return '';
    };

    // Function to convert decimal part to words
    const decimalToWords = (decimal: number): string => {
      if (decimal === 0) return '';
      return 'Point ' + decimal.toString().split('').map(digit => ones[parseInt(digit) - 1]).join(' ');
    };

    const integerPart = Math.floor(value);
    const decimalPart = Math.round((value - integerPart) * 100); // Considering up to two decimal places

    let result = integerToWords(integerPart);
    if (decimalPart > 0) {
      result += ' ' + decimalToWords(decimalPart);
    }

    return result;
  }
}
