import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tables-widget11',
  templateUrl: './tables-widget11.component.html',
})
export class TablesWidget11Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
