import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ConnectBaseService } from 'src/app/_metronic/kt/_utils/base.service';


const API_USERS_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class RoleService extends ConnectBaseService {
    orgId:any
    org(){
        return localStorage.getItem('Org_Id');
    }

    //Role
    getRoleList(all_result?: boolean): Observable<any> {
        let params = `?organization_id=${this.org()}`;
        if (all_result !== undefined) {
          params += `&all_result=${all_result}`;
        }
        return this.get(`organizations/role/${params}`);
      }

    createRole(body: any): Observable<any> {
        return this.post(`organizations/role/?organization_id=${this.org()}`, body);
    }

    updateRole(id: any, body: any): Observable<any> {
        return this.put(`organizations/role/${id}/?organization_id=${this.org()}`, body);
    }

    getRoleRetrieve(id:any):Observable<any>{
        return this.get(`organizations/role/${id}/?organization_id=${this.org()}`);
    }

    deleteRole(id:any):Observable<any>{
        return this.delete(`organizations/role/${id}/?organization_id=${this.org()}`);
    }

    getRolebySearch(searchText:string): Observable<any> {
        return this.get(`organizations/role/?organization_id=${this.org()}&search=${searchText}`);
    }

    // Artifacts
    getArtifacts(): Observable<any> {
        return this.get(`artifacts/?organization_id=${this.org()}`);
    }

    // roleArtifactsMapping
    createUpdateRoleArtifactsMapping(body: any): Observable<any> {
        return this.post(`role-artifact-mapping/?organization_id=${this.org()}`, body);
    }
}
