<!--begin::Mixed Widget 10-->
<div
  class="card card-custom"
  [ngClass]="cssClass"
  [ngStyle]="{ height: widgetHeight }"
>
  <!--begin::Body-->
  <div
    class="
      card-body
      d-flex
      align-items-center
      justify-content-between
      flex-wrap
    "
  >
    <div class="mr-2">
      <h4 class="font-weight-bolder">Create CRM Reports</h4> 
      <div class="text-dark-50 font-size-lg mt-2">
        Generate the latest CRM Report
      </div>
    </div>
    <a href="#" class="btn btn-primary btn-sm font-weight-bold py-3 px-6">Start Now</a>
  </div>
  <!--end::Body-->
</div>
<!--end::Mixed Widget 10-->
