import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { OrganizationService } from 'src/app/modules/common/services/organization.service';
import { environment } from 'src/environments/environment';
import { AuthHTTPService } from './../../../../../modules/auth/services/auth-http';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() appHeaderDefaulMenuDisplay: boolean;
  @Input() isRtl: boolean;

  org: any = localStorage.getItem('organization');

  itemClass: string = 'ms-1 ms-lg-3';
  btnClass: string =
    'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px';
  userAvatarClass: string = 'symbol-35px symbol-md-40px';
  btnIconClass: string = 'svg-icon-1';
  organization: any;
  orgSubscribe: any;
  letters = '0123456789ABCDEF';
  meValue: any;
  geColor: any;
  fullScreenElem: any;
  fullScreenElemClicked: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private authHttpService: AuthHTTPService,
    private organizationService: OrganizationService,) {
    this.orgSubscribe = this.organizationService.org$.subscribe(() => {
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
  this.fullScreenElem = document.documentElement;
   let org: any = localStorage.getItem('organization');
    this.organization = JSON.parse(org);
    if(this.organization == null){
      let org:any = localStorage.getItem(`${environment.appVersion}-${environment.USERDATA_KEY}`);
      this.organization = JSON.parse(org);
    }
    this.geColor = this.getRandomColor() 
    this.getMe();

   }

   getShortName(firstName:any) {
    return `${this.meValue?.first_name?.[0]}${this.meValue?.last_name?.[0]}`;
  }

  getMe() {
    this.authHttpService.getMe().subscribe({
      next: (value: any) => {
        this.meValue = value;
      },
      error: (error: any) => {
       
      },

    });
  }

  getRandomColor() {
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += this.letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  toggleFullScreenMode() {
    this.fullScreenElemClicked = !this.fullScreenElemClicked;

    if (this.fullScreenElemClicked) {
      this.openFullscreen();
    } else {
      this.closeFullscreen();
    }
  }

  openFullscreen() {
    if (this.fullScreenElem.requestFullscreen) {
      this.fullScreenElem.requestFullscreen();
    } else if (this.fullScreenElem.mozRequestFullScreen) {
      /* Firefox */
      this.fullScreenElem.mozRequestFullScreen();
    } else if (this.fullScreenElem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.fullScreenElem.webkitRequestFullscreen();
    } else if (this.fullScreenElem.msRequestFullscreen) {
      /* IE/Edge */
      this.fullScreenElem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

   ngOnDestroy() {
    this.orgSubscribe.unsubscribe();
  }
}
