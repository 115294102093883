<!--begin::Menu wrapper-->
<div id="kt_app_sidebar_menu_wrapper" class="app-sidebar-wrapper hover-scroll-overlay-y my-5" data-kt-scroll="true"
  data-kt-scroll-activate="true" data-kt-scroll-height="auto"
  data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
  data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true">
  <!--begin::Menu-->
  <div class="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true"
    data-kt-menu-expand="false">
    <!-- Dashboard -->
    <!-- <div *ngIf="!isOrgCreate" class="menu-item">
      <a class="menu-link without-sub" [ngClass]="{'active': 'dashboard' === selectedItem}"
        (click)="redirect('dashboard')" routerLinkActive="active"><span class="menu-icon">
          <span [inlineSVG]="'./assets/media/icons/duotune/general/gen025.svg'" class="svg-icon svg-icon-2"></span>
        </span>
        <span class="menu-title" translate="MENU.DASHBOARD"></span></a>
    </div> -->

    <div *ngIf="!isOrgCreate">
      <ng-container *ngFor="let list of artiFacts">
        <div *ngIf="isModuleAccess(list)" class="menu-item menu-accordion"
          [ngClass]="{'hover show': list.slug === selectedItem}" data-kt-menu-trigger="click"
          routerLinkActive="here show">
          <span class="menu-link" [ngClass]="{'active': list.slug === selectedItem}">
            <span class="menu-icon">
              <span class="">
                <img class="invert-06" [src]="list.icon" />
              </span>
            </span>
            <span class="menu-title" data-link="" (click)="navigate(list)">{{list.name}}</span>
            <span *ngIf="list.children?.length"  class="menu-arrow">
            </span>
          </span>

          <div class="menu-sub menu-sub-accordion" *ngIf="list.children" routerLinkActive="menu-active-bg">
            <div class="menu-item menu-accordion" routerLinkActive="here show" data-kt-menu-trigger="click">
              <ng-container *ngFor="let sub of list.children">
                <div class="menu-item" *ngIf="isModuleAccess(sub)">
                  <a class="menu-link without-sub" [ngClass]="{'active': sub.slug === selectedItem}"
                    routerLinkActive="active"><span class="menu-bullet"><span
                        class="bullet bullet-dot"></span></span><span class="menu-title"
                      (click)="navigate(list,sub)">{{sub.name}}</span></a>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="!isOrgCreate" class="menu-item 2">
      <a class="menu-link without-sub" [ngClass]="{'active': 'suggestions' === selectedItem}"
        (click)="redirect('suggestions')" routerLinkActive="active"><span class="menu-icon">
          <span [inlineSVG]="'./assets/media/icons/duotune/general/gen019.svg'" class=""></span>
        </span>
        <span class="menu-title">Suggestions</span>
      </a>
    </div>
    <div *ngIf="isOrgCreate">
      <div class="menu-item menu-accordion" data-kt-menu-trigger="click" routerLinkActive="here show">
        <span class="menu-link"><span class="menu-icon"><span class=""
              [inlineSVG]="'./assets/media/icons/duotune/general/gen019.svg'"></span></span><span class="menu-title"
            data-link="">Organizations</span><span class="menu-arrow"></span></span>

        <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
          <div class="menu-item menu-accordion" routerLinkActive="here show" data-kt-menu-trigger="click">
            <div class="menu-item">
              <a class="menu-link without-sub" routerLink="/organization/add" routerLinkActive="active"><span
                  class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title">Add
                  Organizations</span></a>
            </div>
          </div>
        </div>
      </div>
      <!--end::Menu-->
    </div>
  </div>
</div>
<!--end::Menu wrapper-->