import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { LoaderInterceptor } from './modules/common/interceptors/loader.interceptor';
import { IonicModule } from '@ionic/angular';
import { LoaderModule } from './_metronic/partials/layout/loader/loader.module';
import { AuthHTTPService } from './modules/auth/services/auth-http';
import { finalize, map, switchMap } from 'rxjs';
import { AuthModel } from './modules/auth/models/auth.model';


function appInitializer(
  authService: AuthService,
  authHttpService: AuthHTTPService
) {
  return () => {
    return new Promise((resolve) => {
      //@ts-ignore
      authService.getUserByToken().subscribe().add(resolve);

      const authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

      const isValue = localStorage.getItem(authLocalStorageToken);
      if (isValue) {
        const authData = JSON.parse(isValue);
        authHttpService.refreshToken({'refresh': authData.refreshToken}).subscribe((data) => {
          authService.setAuthFromLocalStorage(data);
        });
      }      
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    IonicModule,
    LoaderModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService, AuthHTTPService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
