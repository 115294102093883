import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../models/auth.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConnectBaseService } from 'src/app/_metronic/kt/_utils/base.service';

const API_USERS_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService extends ConnectBaseService{

  private Me: BehaviorSubject<any> = new BehaviorSubject<any>(null); 
  // constructor(private http: HttpClient) {}

  // public methods
  login(email: string, password: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Device-Type':this.getDeviceType(),
      'Site-Source' : "BNBRUN"
    });
    return this.http.post<AuthModel>(`${API_USERS_URL}/token/`, {
      email,
      password,
    },{
      headers: httpHeaders,
    });
  }

  setMe(data:any):void{
    this.Me.next(data);
  }

  getMe():Observable<any>{
    return this.Me.asObservable();
  }


  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      'Device-Type':this.getDeviceType(),
      'Site-Source' : "BNBRUN"
    });
    return this.http.post<UserModel>(`${API_USERS_URL}/user/`, user,{
      headers: httpHeaders,
    });
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    const httpHeaders = new HttpHeaders({
      'Device-Type':this.getDeviceType(),
      'Site-Source' : "BNBRUN"
    });
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password/`, {
      email,
    },{
      headers: httpHeaders,
    });
  }

  changePassword(verificationkey:any, body:any): Observable<boolean> {
    const httpHeaders = new HttpHeaders({
      'Device-Type':this.getDeviceType(),
      'Site-Source' : "BNBRUN"
    });
    return this.http.post<boolean>(`${API_USERS_URL}/change-password/${verificationkey}`, body,{
      headers: httpHeaders,
    });
  }

  changePasswordValidation(body:any): Observable<boolean> {
    const httpHeaders = new HttpHeaders({
      'Device-Type':this.getDeviceType(),
      'Site-Source' : "BNBRUN"
    });
    return this.http.get<boolean>(`${API_USERS_URL}/change-password/validation/${body}`,{
      headers: httpHeaders,
    });
  }

  refreshToken(body:any): Observable<any>{
    const httpHeaders = new HttpHeaders({
      'Device-Type':this.getDeviceType(),
      'Site-Source' : "BNBRUN"
    });
    return this.http.post<AuthModel>(`${API_USERS_URL}/token/refresh/`, body,{
      headers: httpHeaders,
    });
  }

  getUserByToken(token: string): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Device-Type':this.getDeviceType(),
      'Site-Source' : "BNBRUN"
    });
    return this.http.get<UserModel>(`${API_USERS_URL}/me/`, {
      headers: httpHeaders,
    });
  }

  verifyEmail(email_verification_key: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Device-Type':this.getDeviceType(),
      'Site-Source' : "BNBRUN"
    });
    return this.http.post<any>(`${API_USERS_URL}/verify-email/${email_verification_key}/`, { },{
      headers: httpHeaders,
    });
  }
}
