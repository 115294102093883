<ng-container *ngIf="user$ | async as _user">
  <div class="menu-item px-0">
    <div class="menu-content d-flex align-items-center px-3">
      <div class="d-flex flex-column">
        <div class="fw-bolder d-flex align-items-center fs-5">
          {{ _user.firstname }} {{ _user.lastname }}
        </div>
        <a class="fw-bold text-muted text-hover-primary fs-8 cursor-pointer">
          {{ _user.email }}
        </a>
      </div>
    </div>
  </div>

  <div class="separator my-2"*ngIf="isview"></div>

  <div class="menu-item px-5" *ngIf="isview">
    <a (click)="navigate()" class="menu-link px-5">
      My Profile
    </a>
  </div>

  <div class="separator my-2"></div>


  <div class="menu-item px-5">
    <a (click)="logout()" class="menu-link px-5 cursor-pointer"> Sign Out </a>
  </div>
</ng-container>
