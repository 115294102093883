<app-entity *ngIf="data" [data]="data" [hasParent]="hasParent" (toggleChild)="toggleShowChild()" [hideChild]="hideChild"></app-entity>
<div class="org-chart-container" [style.display]="hideChild ? 'none' : 'flex'">
	<ng-container *ngFor="let data of data?.children; first as isFirst; last as isLast">
		<div class="org-chart-sub-container">
			<div class="org-chart-connector-container">
				<div class="org-chart-connector org-chart-entity-border" [style.border-color]="isFirst?'transparent':''"></div>
				<div class="org-chart-entity-border"></div>
				<div class="org-chart-connector org-chart-entity-border" [style.border-color]="isLast?'transparent':''"></div>
			</div>
			<app-org-chart [data]="data" [hasParent]="true"></app-org-chart>
		</div>
	</ng-container>
</div>