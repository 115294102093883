import { Injectable } from '@angular/core';
import { en_US } from 'src/app/_metronic/kt/_utils/en_us';
import { SweetAlertOptions } from 'sweetalert2';
import { AlertService } from './alert.service';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private day: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private memberBirthdayList = new BehaviorSubject<any[]>([]);
  memberBirthdayList$ = this.memberBirthdayList.asObservable();
  data: any = [];
  private componentDataSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  // This observable will be used to notify changes
  componentData$ = this.componentDataSubject.asObservable();

  setDayValue(data: any): void {
    this.day.next(data);
  }

  getDayValue(): Observable<any> {
    return this.day.asObservable();
  }

  setMemberBirthdayList(data: any[]) {
    this.memberBirthdayList.next(data);
  }

  getMemberBirthdayList() {
    return this.memberBirthdayList.getValue();
  }

  constructor(private alert: AlertService) { }


  calculateItems(price: any, qty: any, data: any, hideDiscount: boolean = true) {
    let item = data;

    const formattedPrice = Math.round(parseFloat(price) * 100) / 100;
    const formattedQty = Math.round(parseFloat(qty) * 100) / 100;

    let totalPrice = formattedPrice * formattedQty;
    totalPrice = Math.round(totalPrice * 100) / 100;

    let totalDiscount = 0;
    let discountablePrice = 0;

    if (data?.discount_type && hideDiscount) {
      if (data?.discount_type === 1) {
        totalDiscount += (totalPrice * parseFloat(data?.discount ?? 0)) / 100;
      } else {
        totalDiscount += parseFloat(data?.discount ?? 0);
      }
    }

    totalDiscount = Math.round(totalDiscount * 100) / 100;

    discountablePrice = totalPrice - totalDiscount;

    let totalTax = 0;
    let totalPercentageTax = 0;
    let taxDetails: any = [];
    if (item?.tax_classes && Array.isArray(item.tax_classes)) {
      item.tax_classes.forEach((tax: any) => {
        if (tax?.percentage) {
          const taxPercentage = parseFloat(tax.percentage);
          let individualTax = (discountablePrice * taxPercentage) / 100;
          individualTax = Math.round(individualTax * 100) / 100;
          taxDetails.push({
            name: tax.name,
            value: taxPercentage,
            individualTax: individualTax
          });
          totalTax += individualTax;
          totalPercentageTax += taxPercentage;
        }
      });
    }
    const finalPrice = discountablePrice + totalTax;
    return {
      totalDiscount: totalDiscount,
      discountablePrice: discountablePrice,
      totalTax: totalTax,
      finalPrice: finalPrice,
      totalPercentageTax: totalPercentageTax,
      taxDetails: taxDetails
    };
  }

  calculateOC(price: any, qty: any, item: any) {
    const totalPrice = parseFloat(price) * parseFloat(qty);
    let totalTax = 0;
    let totalPercentageTax = 0;
    let taxDetails: any = [];
    if (item?.tax_classes && Array.isArray(item.tax_classes)) {
      item.tax_classes.forEach((tax: any) => {
        if (tax?.percentage) {
          const taxPercentage = parseFloat(tax.percentage);
          const individualTax = (totalPrice * taxPercentage) / 100;
          taxDetails.push({
            name: tax.name,
            value: taxPercentage,
            individualTax: individualTax
          });
          totalTax += individualTax;
          totalPercentageTax += taxPercentage;
        }
      });
    }
    const finalPrice = totalPrice + totalTax;
    return {
      totalTax: totalTax,
      finalPrice: finalPrice,
      totalPercentageTax: totalPercentageTax,
      taxDetails: taxDetails
    };
  }

  Finalcalculate(data: any) {
    let finalPrice = 0;
    let discountablePrice = 0
    let totalTax = 0
    let netOcPrice = 0
    let taxList: any = []
    data?.items?.forEach((item: any) => {
      const finalPriceData = this.calculateItems(item.price, item.qty, item);
      finalPrice += finalPriceData.finalPrice;
      discountablePrice += finalPriceData.discountablePrice,
        totalTax += finalPriceData.totalTax
      taxList.push(finalPriceData.taxDetails)
    });

    data?.taxable_other_charges?.forEach((item: any) => {
      const finalPriceData = this.calculateOC(item.amount, 1, item);
      finalPrice += finalPriceData.finalPrice;
      discountablePrice += item.amount,
        netOcPrice += item.amount,
        totalTax += finalPriceData.totalTax
      taxList.push(finalPriceData.taxDetails)
    });
    return {
      finalPrice: finalPrice,
      discountablePrice: discountablePrice,
      totalTax: totalTax,
      netOcPrice: netOcPrice,
      taxDetails: taxList
    };
  }

  combineTaxLists(data: any): any[] {
    const combined: any = {};
    for (const sublist of data) {
      for (const item of sublist) {
        const { name, value, individualTax } = item;
        if (combined[name]) {
          combined[name].value += value;
          combined[name].individualTax += individualTax;
        } else {
          combined[name] = { name, value, individualTax };
        }
      }
    }
    return Object.values(combined);
  }

  purchasedata(data: any) {
    let supplierKeyName: any = data?.supplier_details ? 'supplier_details' : `supplier_data`
    return {
      currencyName: data?.[supplierKeyName]?.currency_name ?? null
    }
  }

  checkPriceList(user: any, priceList: any, userKeyName = 'vendors') {
    if (!user || !priceList?.data) return false;
    const item = priceList.data[userKeyName];
    const address = userKeyName === 'vendors' ? 'address' : 'customer_billing_address_data'
    if (Array.isArray(item) && item.length > 0 && !item.includes(user.id)) {
      return false;
    }
    if (priceList.data.country_name?.toLowerCase() !== user[address]?.country_name?.toLowerCase()) {
      return false;
    }
    if (priceList.data.state_name && priceList.data.state_name.toLowerCase() !== user[address]?.state?.toLowerCase()) {
      return false;
    }
    if (priceList.data.city_name && priceList.data.city_name.toLowerCase() !== user[address]?.city?.toLowerCase()) {
      return false;
    }
    return {
      user: user,
      price_list: priceList
    };
  }

  findByKeyAndValue(key: string, value: any, list: any[]): any | null {
    for (let item of list) {
      if (item[key] === value) {
        return item;
      }

      if (item.children && item.children.length > 0) {
        const foundChild = this.findByKeyAndValue(key, value, item.children);
        if (foundChild) {
          return foundChild;
        }
      }
    }

    return null;
  }



  isRestrictedByUserLevel(moduleName: any, list: any, workflowApprovals?: any) {
    const currentUser: any = localStorage.getItem(`${environment.appVersion}-${environment.USERDATA_KEY}`);
    const authData = JSON.parse(currentUser);
    const org: any = localStorage.getItem('organization');
    const organization = JSON.parse(org);

    const defaultPermissions = {
      is_create: true,
      is_read: true,
      is_update: true,
      is_delete: true,
      is_approval_enable: true,
      is_eway_bill_generated: true,
      is_einvoice_generated: true,
    };

    if (authData.user_id === organization.owner) {
      return defaultPermissions;
    }

    const item = workflowApprovals['WorkflowApproval']?.find((item: any) => item?.name === moduleName);
    const isApprovals = item?.members?.includes(organization.member_id);

    if (isApprovals) {
      return defaultPermissions;
    }

    const isRestriction = workflowApprovals['restriction'];
    if (isRestriction?.is_edit_restricted && list?.created_by === authData?.user_id) {
      return defaultPermissions;
    }

    if (!isRestriction?.is_edit_restricted && !isRestriction?.is_view_restricted) {
      return defaultPermissions;
    }

    if (list?.created_by === authData?.user_id) {
      return defaultPermissions;
    }

    return {
      is_create: false,
      is_read: true,
      is_update: false,
      is_delete: false,
      is_approval_enable: true,
      is_eway_bill_generated: true,
      is_einvoice_generated: true,
    };
  }


  adjustDateByDays(days: number, format: string = 'YYYY-MM-DD'): string {
    const today = new Date();
    const adjustedDate = new Date(today);
    adjustedDate.setDate(today.getDate() + days);

    function formatDate(date: Date, format: string): string {
      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');

      return format
        .replace('YYYY', year)
        .replace('MM', month)
        .replace('DD', day);
    }
    return formatDate(adjustedDate, format);
  }


  storeConditions(data: any[], value: number, isBatchEnable = false): {
    isUnique: boolean,
    remainingQty: number,
    isLess: boolean,
    addressValid: boolean,
    errorMessage: string | null
  } {
    let errorMessage: string | null = null;
    let isUnique = true;
    let isLess = false;
    let addressValid = true;
    if (!data || data.length === 0) {
      return { isUnique: false, remainingQty: 0, isLess: false, addressValid: false, errorMessage: en_US.duplicate_error };
    }
    const ids = data.map((item: any) => item.store_name);
    const hasInvalidIds = ids.some((id: any) => !id);
    if (!hasInvalidIds) {
      if (isBatchEnable) {
        const uniqueStoreBatch = new Set(data.map((item: any) => `${item.store_name}-${item.batch_id}`));
        isUnique = uniqueStoreBatch.size === data.length;
      } else {
        const uniqueStoreNames = new Set(ids);
        isUnique = uniqueStoreNames.size === data.length;
      }
    }
    const totalQty = data.reduce((acc, item) => acc + (parseFloat(item.qty) || 0), 0);
    if (!isUnique || hasInvalidIds) {
      errorMessage = en_US.duplicate_error;
      return { isUnique, remainingQty: value - totalQty, isLess: false, addressValid: false, errorMessage };
    }
    isLess = value < totalQty;
    if (isLess) {
      errorMessage = en_US.store_quantity_error;
      return { isUnique, remainingQty: value - totalQty, isLess, addressValid: false, errorMessage };
    }
    if (value - totalQty !== 0) {
      errorMessage = 'Please complete your remaining Quantity';
      return { isUnique, remainingQty: value - totalQty, isLess, addressValid: false, errorMessage };
    }
    for (const item of data) {
      if (item.address === undefined && item.store_name) {
        addressValid = false;
        errorMessage = en_US.store_address_error;
        break;
      }
    }
    return { isUnique, remainingQty: value - totalQty, isLess, addressValid, errorMessage };
  }

  checkLineItemsStoreQTY(data: any, isBatchEnable = false) {
    let isValid = true;
    for (let i = 0; i < data.length; i++) {
      isBatchEnable = data[i]?.item?.is_batch_enable ?? false
      const storeQty = data[i].store_qty
      const qty = data[i].qty;
      const itemCheck = this.storeConditions(storeQty, qty, isBatchEnable)
      if (itemCheck?.remainingQty !== 0 || itemCheck?.errorMessage !== null) {
        isValid = false;
        break;
      }
    }
    if (!isValid) {
      const errorOptions: SweetAlertOptions = {
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        icon: 'error',
        timer: 5000,
        title: `Store quantity and Quantity must be the same for all items.`
      };
      this.alert.showAlert(errorOptions);
      return false
    }
    return true
  }

  validateCustomFieldsData(items: any[]): boolean {
    for (let i = 0; i < items.length; i++) {
      const customFieldsData = items[i].custom_fields_data;
      if (!customFieldsData || !customFieldsData.dynamic_data) {
        continue;
      }
      const requiredFields = customFieldsData.dynamic_data.filter((field: any) => field.is_required);
      const dynamicDataValue = customFieldsData.dynamic_data_value || {};
      for (let field of requiredFields) {
        const fieldKey = this.getControlName(field.label_name)
        const fieldValue = dynamicDataValue[fieldKey];
        if (!fieldValue || fieldValue.trim() === '') {
          return false;
        }
      }
    }
    return true;
  }

  getControlName(fieldName: string): string {
    return fieldName?.replace(/\s+/g, '_').toLowerCase();
  }

  areKeysUnique(objects: any[], keys: string[]): any {
    const uniqueKeys = new Set<string>();
    const invalidIndices: number[] = [];

    for (let i = 0; i < objects.length; i++) {
      const obj = objects[i];
      const key = keys.map(k => obj[k]).join('-');

      if (uniqueKeys.has(key)) {
        invalidIndices.push(i);
      } else {
        uniqueKeys.add(key);
      }
    }

    return {
      isUnique: invalidIndices.length === 0,
      invalidIndices: invalidIndices
    };
  }

  convertToValueLabelArray(accounts: any[]): { value: number; label: string }[] {
    let result: { value: number; label: string }[] = [];
    accounts.forEach(account => {
      result.push({
        value: account.id,
        label: account.account_name
      });
      if (account.children && account.children.length > 0) {
        result = result.concat(this.convertToValueLabelArray(account.children));
      }
    });
    return result;
  }

  generateUNQID(key: string, data: any): string {
    const keys = key.split('-');

    let unqid = keys.map(k => {
      const value = k.split('.').reduce((o, i) => (o && o[i] !== undefined) ? o[i] : 'false', data);
      return value === undefined || value === null ? 'false' : value;
    }).join('-');

    return unqid;
  }
  hsnCodeConditionalValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const isNumeric = /^\d+$/.test(control.value);
      if (!isNumeric) {
        return { 'hsnCodeInvalid': true };
      }
      const valueLength = control.value.length;
      if (valueLength < 4 || valueLength > 8) {
        return { 'hsnCodeLength': true };
      }
      return null;
    };
  }


  getCurrentTimezone(): string {
    const offsetMinutes = new Date().getTimezoneOffset();
    const hours = Math.floor(Math.abs(offsetMinutes) / 60);
    const minutes = Math.abs(offsetMinutes) % 60;

    const sign = offsetMinutes > 0 ? '-' : '+';
    const formattedTimeZone = `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

    return formattedTimeZone;
  }

  setComponentData(componentName: string, type: string, data: any): void {
    if (!this.data[componentName]) {
      this.data[componentName] = {};
    }
    this.data[componentName][type] = data;

    // Notify other components after setting the data
    this.componentDataSubject.next({
      componentName,
      type,
      data: this.getComponentData(componentName, type)
    });
  }

  getComponentData(componentName: string, type: string): any {
    if (this.data[componentName] && this.data[componentName][type] !== undefined) {
      return this.data[componentName][type];
    }
    return null;
  }

  clearComponentData(componentName: string, type?: string): void {
    if (type) {
      if (this.data[componentName]) {
        delete this.data[componentName][type];
        if (Object.keys(this.data[componentName]).length === 0) {
          delete this.data[componentName];
        }
      }
    } else {
      delete this.data[componentName];
    }

    // Notify about the change in data
    this.componentDataSubject.next({
      componentName,
      type,
      data: null
    });
  }
}

