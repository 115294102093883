import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrganizationService } from 'src/app/modules/common/services/organization.service';

@Component({
  selector: 'app-quotation-attachments-comments',
  templateUrl: './quotation-attachments-comments.component.html',
  styleUrls: ['./quotation-attachments-comments.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule]
})
export class QuotationAttachmentsCommentsComponent  implements OnInit {
    
  showAttachments: boolean = true;
  showAdditionalDetails: boolean = true;
  showComment: boolean = true;
  showSignature: boolean = true;
  selectedButtons: string[] = [];
  isDragOver = false;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizationService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      signature: [''],
      attachments: this.fb.array([])
    });
  }

  get attachments(): FormArray {
    return this.form?.get('attachments') as FormArray;
  }

  handleSignatureUpload(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const avatarImageBase64string = reader.result;
        this.form?.get('signature')?.setValue(avatarImageBase64string);
        this.cdr.detectChanges();
      };
      reader.readAsDataURL(file);
    }
    event.target.value = '';
  }

  toggleAttachments(type: string, button: string): void {
    const index = this.selectedButtons.indexOf(button);
    
    if (index === -1) {
      this.selectedButtons.push(button);
    } else {
      this.selectedButtons.splice(index, 1);
    }
    if (type === 'Attachments') {
      this.showAttachments = !this.showAttachments;
    } else if (type === 'Additional Details') {
      this.showAdditionalDetails = !this.showAdditionalDetails;
    } else if (type === 'Comment') {
      this.showComment = !this.showComment;
    } else if (type === 'Attach Signature') {
      this.showSignature = !this.showSignature;
    }
  }

  handleAttachmentsUpload(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const fileBase64string = reader.result;
        this.attachments?.push(this.fb.group({ file: fileBase64string }));
        this.cdr.detectChanges();
      };
      reader.readAsDataURL(file);
    }
    event.target.value = '';
  }

  emitFormData() {
    return this.form.value;
  }
}
