import { ChangeDetectorRef, Component, ElementRef, Input, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { ModalConfig } from '../modal.config';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() public modalConfig: ModalConfig;
  @ViewChild('modal') private modalContent: TemplateRef<ModalComponent>;
  @Input() showTaskDetailsIcon: boolean;
  @Input() taskId: any;
  @Input() project_id: any;
  userAccess: any;
  private modalRef: NgbModalRef;
  dynamicMaxWidth: string = '650px';
  constructor(private modalService: NgbModal, private cdr: ChangeDetectorRef, private renderer: Renderer2, private el: ElementRef , private router: Router,
    ) {
    this.router.events.pipe(
      filter((event:any) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      ;
      this.close()
      });
   }

  open(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let dynamicClass = '';

      if (this.modalConfig.dynamicMaxWidth == '100vw') {
        dynamicClass += 'w-100vw';
      }

      if (this.modalConfig.dynamicMaxWidth == '831px') {
        dynamicClass += 'w-831px';
      }

      if (this.modalConfig.dynamicMaxWidth == '1000px') {
        dynamicClass += 'w-1000px';
      }
      const modalOptions = {
        windowClass: dynamicClass
      };
      this.modalRef = this.modalService.open(this.modalContent, modalOptions);
      this.modalRef.result.then(resolve, resolve);
    });
  }
  async close(): Promise<void> {
    if (
      this.modalConfig?.shouldClose === undefined ||
      (await this.modalConfig?.shouldClose())
    ) {
      const result =
        this.modalConfig?.onClose === undefined ||
        (await this.modalConfig?.onClose());
      this.modalRef?.close(result);
    }
  }

  taskDetails(id: any) {
    const url = '/project-management/' + this.project_id + '/task-details/' + id;
    window.open(url, '_blank');
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.disableDismissButton !== undefined) {
      return;
    }

    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss());
      if (!result) {
        return;
      }
      this.modalRef.dismiss(result);
    }
  }
}
