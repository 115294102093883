<ng-container *ngIf="customTable === 'table-lg'">
  <div [ngClass]="tableName ? 'card mb-5 mb-xl-8' : ''">
    <!--begin::Header-->
    <div class="">
      <!--begin::Table container-->
      <div class="card-body pb-1">
        <form [formGroup]="form" class="table-responsive">
          <table class="table table-bordered gs-4 gy-4 gx-4">
            <!--begin::Table head-->
            <thead class="align-items-center justify-content-center text-center">
              <tr class="ffw-semibold fs-7 text-gray-800 border-gray-200 custom-table-border">
                <ng-container *ngFor="let header of tableHeader">
                  <th [ngClass]="header?.class_name" [class.required]="header?.required" *ngIf="header.show">
                    {{header?.title}}</th>
                </ng-container>
              </tr>
            </thead>
            <!--end::Table head-->
            <!--begin::Table body-->
            <tbody [formArrayName]="'rows'" class="custom-table-border">
              <tr *ngFor="let row of rows?.controls; let rowIndex = index" [formGroupName]="rowIndex">
                <ng-container *ngFor="let header of tableHeader">
                  <td *ngIf="header.show && !header?.plain_text" class="align-items-center justify-content-center text-center" [class]="header.class_name">
                    <a class="text-dark fw-bold text-hover-primary d-block mb-1 fs-6" *ngIf="header?.title === '#'">{{
                      rowIndex + 1 }}
                      <i *ngIf="header?.rowDelete && readonlyValue && (rows?.controls!.length > 1 || !defaultRow)"
                        (click)="removeRow(row)" class="fa fa-lg fa-trash text-danger cursor-pointer">
                      </i>
                    </a>
                    <input [readonly]="header.readonly"
                      *ngIf="header.control_name && header?.title != '#' && header?.input_type != 'checkbox' && header?.input_type != 'dropDown'"
                      class="form-control p-1 fv-plugins-message-container" (focus)="getControlValue(header.control_name, rowIndex)"
                      [formControlName]="header.control_name" [type]="header?.input_type ?? 'text' " (input)="restrictInput($event, header.input_type)"
                      [ngClass]="header?.input_class"
                      [ngClass]="{'invalid': isInvalid(header.control_name, rowIndex) && header?.required}" />
                    <select2 [class.disable]="header.readonly" *ngIf="header?.input_type === 'dropDown'"
                      (update)="update(rowIndex,$event,header?.control_name,row)" [overlay]="overlay"
                      [ngClass]="{'invalid': isInvalid(header.control_name, rowIndex) && header?.required}"
                      class="form-control-solid fv-plugins-message-container" [data]="getDropdownValue(header.control_name,rowIndex,header?.custom_condition)"
                      [formControlName]="header.control_name"></select2>
                    <div *ngIf="header?.input_type === 'checkbox'"
                      class="form-check form-check-custom form-check-solid mx-5">
                      <ng-container
                        *ngIf="header?.custom_condition?.hideCheckBox && row.get('custom_is_completed')?.value; else condition2Block">
                        <input class="form-check-input" disabled="true" type="checkbox" value="true" checked="true" />
                      </ng-container>
                      <ng-template #condition2Block>

                        <input class="form-check-input" [formControlName]="header.control_name" type="checkbox"
                          value="" />
                      </ng-template>
                      <i class="fa-solid fs-2 text-success me-3 fa-circle-check ms-3"
                        *ngIf="header?.custom_condition?.hideCheckBox && row.get('custom_is_completed')?.value"></i>
                    </div>
                  </td>
                  <td *ngIf="header.plain_text" class="fs-6 fw-bold">
                    <a [ngClass]="header?.input_class"> {{ header.control_name ? row.get(header?.control_name)?.value :
                      '-' }}</a>
                  </td>

                </ng-container>
              </tr>
            </tbody>
            <tbody class="fw-bold text-gray-600" *ngIf="rows?.controls?.length == 0">
              <tr class="odd">
                <td valign="top" [attr.colspan]="tableHeader?.length" class="dataTables_empty text-center">No matching
                  records found</td>
              </tr>
            </tbody>
            <!--end::Table body-->
          </table>
        </form>


        <!--end::Table-->
      </div>
      <button *ngIf="addMultiRowName != 'N/A' && readonlyValue" type="button" data-repeater-create=""
        class="btn btn-sm btn-light-primary" style="margin-right: 30px;" (click)="addNewRow()">
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr087.svg-->
        <span class="svg-icon svg-icon-2">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)"
              fill="currentColor" />
            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor" />
          </svg>
        </span>{{addMultiRowName}}</button>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
</ng-container>