<div class="card-body p-0">
  <!-- begin::Header -->
  <div class="px-9 pt-7 card-rounded h-275px w-100" [ngClass]="'bg-' + color">
    <!-- begin::Heading -->
    <div class="d-flex flex-stack">
      <h4 class="m-0 text-white fw-bolder fs-3">Sales Summary</h4> 
      <div class="ms-1">
        <!-- begin::Menu -->
        <button
          type="button"
          class="
            btn btn-sm btn-icon btn-color-white btn-active-white
            border-0
            me-n3
          "
          [ngClass]="'btn-active-color-' + color"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <span
            [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'"
            class="svg-icon svg-icon-2"
          ></span>
        </button>
        <app-dropdown-menu1></app-dropdown-menu1>
        <!-- end::Menu -->
      </div>
    </div>
    <!-- end::Heading -->
    <!-- begin::Balance -->
    <div class="d-flex text-center flex-column text-white pt-8">
      <span class="fw-bold fs-7">You Balance</span>
      <span class="fw-bolder fs-2x pt-1">$37,562.00</span>
    </div>
    <!-- end::Balance -->
  </div>
  <!-- end::Header -->
  <!-- begin::Items -->
  <div
    class="
      shadow-xs
      card-rounded
      mx-9
      mb-9
      px-6
      py-9
      position-relative
      z-index-1
      bg-body
    "
    [style.margin-top]="'-100px'"
  >
    <!-- begin::Item -->
    <div class="d-flex align-items-center mb-6">
      <!-- begin::Symbol -->
      <div class="symbol symbol-45px w-40px me-5">
        <span class="symbol-label bg-lighten">
          <span
            [inlineSVG]="'./assets/media/icons/duotune/general/gen005.svg'"
            class="svg-icon svg-icon-1"
          ></span>
        </span>
      </div>
      <!-- end::Symbol -->
      <!-- begin::Description -->
      <div class="d-flex align-items-center flex-wrap w-100">
        <!-- begin::Title -->
        <div class="mb-1 pe-3 flex-grow-1">
          <a href="#" class="fs-5 text-gray-800 text-hover-primary fw-bolder">
            Sales
          </a>
          <div class="text-gray-400 fw-bold fs-7">100 Regions</div>
        </div>
        <!-- end::Title -->
        <!-- begin::Label -->
        <div class="d-flex align-items-center">
          <div class="fw-bolder fs-5 text-gray-800 pe-1">$2,5b</div>
          <span
            [inlineSVG]="'./assets/media/icons/duotune/arrows/arr066.svg'"
            class="svg-icon svg-icon-5 svg-icon-success ms-1"
          ></span>
        </div>
        <!-- end::Label -->
      </div>
      <!-- end::Description -->
    </div>
    <!-- end::Item -->
    <!-- begin::Item -->
    <div class="d-flex align-items-center mb-6">
      <!-- begin::Symbol -->
      <div class="symbol symbol-45px w-40px me-5">
        <span class="symbol-label bg-lighten">
          <span
            [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'"
            class="svg-icon svg-icon-1"
          ></span>
        </span>
      </div>
      <!-- end::Symbol -->
      <!-- begin::Description -->
      <div class="d-flex align-items-center flex-wrap w-100">
        <!-- begin::Title -->
        <div class="mb-1 pe-3 flex-grow-1">
          <a href="#" class="fs-5 text-gray-800 text-hover-primary fw-bolder">
            Revenue
          </a>
          <div class="text-gray-400 fw-bold fs-7">Quarter 2/3</div>
        </div>
        <!-- end::Title -->
        <!-- begin::Label -->
        <div class="d-flex align-items-center">
          <div class="fw-bolder fs-5 text-gray-800 pe-1">$1,7b</div>
          <span
            [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'"
            class="svg-icon svg-icon-5 svg-icon-danger ms-1"
          ></span>
        </div>
        <!-- end::Label -->
      </div>
      <!-- end::Description -->
    </div>
    <!-- end::Item -->
    <!-- begin::Item -->
    <div class="d-flex align-items-center mb-6">
      <!-- begin::Symbol -->
      <div class="symbol symbol-45px w-40px me-5">
        <span class="symbol-label bg-lighten">
          <span
            [inlineSVG]="'./assets/media/icons/duotune/electronics/elc005.svg'"
            class="svg-icon svg-icon-1"
          ></span>
        </span>
      </div>
      <!-- end::Symbol -->
      <!-- begin::Description -->
      <div class="d-flex align-items-center flex-wrap w-100">
        <!-- begin::Title -->
        <div class="mb-1 pe-3 flex-grow-1">
          <a href="#" class="fs-5 text-gray-800 text-hover-primary fw-bolder">
            Growth
          </a>
          <div class="text-gray-400 fw-bold fs-7">80% Rate</div>
        </div>
        <!-- end::Title -->
        <!-- begin::Label -->
        <div class="d-flex align-items-center">
          <div class="fw-bolder fs-5 text-gray-800 pe-1">$8,8m</div>
          <span
            [inlineSVG]="'./assets/media/icons/duotune/arrows/arr066.svg'"
            class="svg-icon svg-icon-5 svg-icon-success ms-1"
          ></span>
        </div>
        <!-- end::Label -->
      </div>
      <!-- end::Description -->
    </div>
    <!-- end::Item -->
    <!-- begin::Item -->
    <div class="d-flex align-items-center">
      <!-- begin::Symbol -->
      <div class="symbol symbol-45px w-40px me-5">
        <span class="symbol-label bg-lighten">
          <span
            [inlineSVG]="'./assets/media/icons/duotune/general/gen005.svg'"
            class="svg-icon svg-icon-1"
          ></span>
        </span>
      </div>
      <!-- end::Symbol -->
      <!-- begin::Description -->
      <div class="d-flex align-items-center flex-wrap w-100">
        <!-- begin::Title -->
        <div class="mb-1 pe-3 flex-grow-1">
          <a href="#" class="fs-5 text-gray-800 text-hover-primary fw-bolder">
            Dispute
          </a>
          <div class="text-gray-400 fw-bold fs-7">3090 Refunds</div>
        </div>
        <!-- end::Title -->
        <!-- begin::Label -->
        <div class="d-flex align-items-center">
          <div class="fw-bolder fs-5 text-gray-800 pe-1">$270m</div>
          <span
            [inlineSVG]="'./assets/media/icons/duotune/arrows/arr065.svg'"
            class="svg-icon svg-icon-5 svg-icon-danger ms-1"
          ></span>
        </div>
        <!-- end::Label -->
      </div>
      <!-- end::Description -->
    </div>
    <!-- end::Item -->
  </div>
  <!-- end::Items -->
</div>
