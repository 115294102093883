import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './modules/auth/services/auth.guard';
import { RoutePath } from './_metronic/kt/_utils/routing.enum';

export const routes: Routes = [
  {
    path: RoutePath.RoutePath_1,
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: RoutePath.RoutePath_2,
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: `${RoutePath.RoutePath_137}/:id`,
    loadComponent:()=> 
      import('./modules/appointment/view-booking-page/view-booking-page.component').then((m) => m.ViewBookingPageComponent),
  },
  {
    path: `${RoutePath.RoutePath_151}/:id`,
    loadComponent:()=> 
      import('./modules/purchase/public-view-inquiry/public-view-inquiry.component').then((m) => m.PublicViewInquiryComponent),
  },
  {
    path: `${RoutePath.RoutePath_152}/:id/quotation`,
    loadComponent:()=> 
      import('./modules/purchase/public-quotation/public-quotation.component').then((m) => m.PublicQuotationComponent),
  },  
  {
    path: `${RoutePath.RoutePath_152}/:id/quotation/${RoutePath.RoutePath_153}`,
    loadComponent:()=> 
      import('./modules/purchase/public-invoice/public-invoice.component').then((m) => m.PublicInvoiceComponent),
  },
  {
    path: `${RoutePath.RoutePath_167}/:id`,
    loadComponent:()=> 
      import('./modules/purchase/public-purchase-order/public-purchase-order.component').then((m) => m.PublicPurchaseOrderComponent),
  },
  {
    path: RoutePath.Root,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./_metronic/layout/layout.module').then((m) => m.LayoutModule),
  },
  { path: RoutePath.Wildcard, redirectTo: `${RoutePath.RoutePath_2}/${RoutePath.RoutePath_3}` },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
