import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { CommonService } from 'src/app/modules/common/services/common.service';

@Directive({
  selector: '[appTaxSummary]'
})
export class TaxSummaryDirective implements OnInit {
  @Input() data: any;
  @Input() ComponentName:any

  constructor(private el: ElementRef, private commonservice: CommonService) {}

  ngOnInit() {
    this.renderTaxSummary();
  }

  private renderTaxSummary() {
    const calculationData = this.commonservice.Finalcalculate(this.data);
    const taxDetails = this.commonservice.combineTaxLists(calculationData.taxDetails);
    const currency = this.ComponentName === 'purchase' ? this.commonservice.purchasedata(this.data)?.currencyName :  this.data?.currency_name ? this.data.currency_name : '';
    
    let taxHeaders: string[];
    let taxValues: string[];
  
    if (this.data.countryOne?.country_name?.toLowerCase() === 'india' && this.data.countryTwo?.country_name?.toLowerCase() === 'india') {
      if (this.data.countryOne.state.toLowerCase() === this.data.countryTwo.state.toLowerCase()) {
        taxHeaders = ['CGST', 'SGST'];
        taxValues = [(calculationData.totalTax / 2).toFixed(3), (calculationData.totalTax / 2).toFixed(3)];
      } else {
        taxHeaders = ['IGST'];
        taxValues = [calculationData.totalTax.toFixed(3)];
      }
    } else {
      taxHeaders = taxDetails.map((tax: any) => tax.name);
      taxValues = taxDetails.map((tax: any) => tax.individualTax.toFixed(3));
    }
  
    const taxTableHeaders = taxHeaders.map(header => `<th class="w-50px bg-light  text-center pb-2">${header}</th>`).join('');
    const taxTableValues = taxValues.map(value => `<td class="text-center w-50px">${currency} ${value }</td>`).join('');
 
    const totalRows = `
      <div class="row justify-content-end font-size-large mb-6">
        <div class="pl-0 pl-lg-3 amount_label col-lg-5 col"><strong>Total <span class="extra_label">(before tax)</span> :</strong></div>
        <div class="amount_value col-lg-3 col d-flex justify-content-end">${currency} ${(calculationData.discountablePrice -  calculationData.netOcPrice).toFixed(3)}</div>
      </div>
      <div class="row justify-content-end font-size-large mb-6">
        <div class="pl-0 pl-lg-3 amount_label col-lg-5 col"><strong>Other Charges <span class="extra_label">(before tax)</span> :</strong></div>
        <div class="amount_value col-lg-3 col d-flex justify-content-end">${currency} ${calculationData.netOcPrice.toFixed(3) }</div>
      </div>
      <div class="row justify-content-end font-size-large mb-6">
        <div class="pl-0 pl-lg-3 amount_label col-lg-5 col"><strong>Total Tax :</strong></div>
        <div class="amount_value col-lg-3 col d-flex justify-content-end">${currency} ${calculationData.totalTax.toFixed(3)}</div>
      </div>
      <div class="row justify-content-end font-size-large mb-6">
        <div class="pl-0 pl-lg-3 amount_label col-lg-5 col"><strong>Total <span class="extra_label">(after tax)</span> :</strong></div>
        <div class="amount_value col-lg-3 col d-flex justify-content-end">${currency} ${calculationData.finalPrice.toFixed(3)}</div>
      </div>
      <div class="row justify-content-end font-size-large mb-6">
        <div class="pl-0 pl-lg-3 amount_label col-lg-5 col">
          <span class="badge badge-success flex-shrink-0 align-self-center me-2 fs-7">Round-off</span>
          <strong class="me-2">Grand Total :</strong>
        </div>
        <div class="amount_value col-lg-3 col d-flex justify-content-end">${currency} ${calculationData.finalPrice.toFixed(0)}</div>
      </div>
    `;
  
    const htmlContent = `
      <div class="row justify-content-end col-6">
        <div class="table-responsive tax-table mb-3">
          <table class="table table-row-dashed table-row-gray-300 table-bordered align-middle gs-0 gy-2 mb-0">
            <thead><tr class="fs-6 fw-bold">${taxTableHeaders}</tr></thead>
            <tbody><tr>${taxTableValues}</tr></tbody>
          </table>
        </div>
      </div>
      ${totalRows}
    `;
  
    this.el.nativeElement.innerHTML = htmlContent;
  }
  
}
