<div class="mb-5 m-xl-3 rounded border border-secondary">
  <!--begin::Header-->
  <div class="card-header pt-5 bg-light">
    <h4 class="card-title align-items-start flex-column">
      <span class="card-label fw-bold fs-3 mb-1">Suppliers</span>
    </h4>
  </div>
  <!--end::Header-->
  <div class="card-body pb-3 pt-0">
    <!--begin::Supplier List-->
    <div>
      <div class="mb-4 border-bottom">
        <div class="card-header row mb-4 border-bottom-2">
          <div class="col-md-6 fv-row text-center">
            <label class="fs-6 fw-semibold"></label>
          </div>
          <div class="col-md-1 fv-row text-center">
            <label class="fs-6 fw-semibold"></label>
          </div>
        </div>
        <form [formGroup]="form">
          <div class="row mb-4">
            <div class="col-md-6 fv-row">
              <select2 [overlay]="overlay" class="form-control form-control-solid" [data]="newMemberList"
                formControlName="suppliers" multiple></select2>
            </div>
            <!-- <div class="col-md-1 d-flex align-items-center justify-content-center">
              <a class="fa fa-circle-minus fs-7 text-danger btn-sm cursor-pointer" 
                 (click)="removeSupplier(i)" *ngIf="suppliers.length > 1">
              </a>
            </div> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</div>