import { environment } from "src/environments/environment";


export class OrganizationHandlerUtil {
    private static sendDataToSubdomain(orgId: any, subdomain: string, key: any, value: any, targetOpen: any): void {
        const queryParams = new URLSearchParams();
        queryParams.set('key', orgId);
        queryParams.set('value', value.refreshToken);
        const targetUrl = `${subdomain}/dashboard?${queryParams.toString()}`;
        window.open(targetUrl, targetOpen);
    }
    
    public static goToOrgWorkSpace(orgId: any, subdomain: any = '', targetOpen='_blank', dropdown: any = true) {
        const authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
        const currentUrl = window.location.href;

        subdomain = `${subdomain}.${environment.rootDomain}`;

        const urlParts = currentUrl.split('/');
        subdomain = `${urlParts[0]}//${subdomain}`;
        const lsValue = localStorage.getItem(authLocalStorageToken);
        
        if (dropdown && lsValue) {
            const token = JSON.parse(lsValue)
            this.sendDataToSubdomain(orgId, subdomain, authLocalStorageToken, token, targetOpen);
        }
    }
}