
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getStatusText } from 'angular-in-memory-web-api';
import { BehaviorSubject, Observable, catchError, map, of, throwError } from 'rxjs';

import { AuthModel } from 'src/app/modules/auth/models/auth.model';
import { AlertService } from 'src/app/modules/common/services/alert.service';
import { environment } from 'src/environments/environment';
import { SweetAlertOptions } from 'sweetalert2';

const API_USERS_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root',
})
export class ConnectBaseService {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  isLoadingSubject: BehaviorSubject<boolean>;
  bearerToken: HttpHeaders | { [header: string]: string | string[]; } | undefined;
  currentArtiFactsSubject: BehaviorSubject<any> | undefined;
  currentArtiFactsItems$: Observable<any> | undefined;
  constructor(protected http: HttpClient, private alert: AlertService) {
    const auth = this.getAuthFromLocalStorage();
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentArtiFactsSubject = new BehaviorSubject<any>(undefined);
    this.currentArtiFactsItems$ = this.currentArtiFactsSubject.asObservable();
  }

  setArtiFactsItems(user: any) {
    this.currentArtiFactsSubject!.next(user);
  }

  getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  authorization(multipart: boolean = false) {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return new HttpHeaders({
        'device-type': this.getDeviceType(),
        'Site-Source' : "BNBRUN"
      });
    }

    let headers

    if (multipart) {
      headers = new HttpHeaders({
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL',
        'device-type': this.getDeviceType(),
        'Site-Source' : "BNBRUN"
      });
    } else {
      headers = new HttpHeaders({
        Authorization: `Bearer ${auth.authToken}`,
        'device-type': this.getDeviceType(),
        'Site-Source' : "BNBRUN"
      });
    }

    this.isLoadingSubject.next(true);

    return headers;
  }

  getDeviceType(): string {
    if (window.navigator.userAgent.match(/Android/i)) {
      return 'android'; // Mobile environment on Android
    } else if (window.navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      return 'ios'; // Mobile environment on iOS
    } else {
      return 'web browser'; // Web browser environment
    }
  }

  public handleResponse(response: HttpResponse<any>): any {
    const statusCode: number = response.status;
    if (statusCode === 500) {
      this.Alert(response.statusText)
    }

    return response.body;
  }

  private Alert(data: any): any {

    const options: SweetAlertOptions = {
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      icon: 'error',
      timerProgressBar: true,
      timer: 5000,
      title: `${data}`,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    };
    this.alert.showAlert(options)
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    const statusCode: number = error.status;
    if (statusCode === 401) {
      localStorage.clear();
      setTimeout(() => {
        document.location.reload();
      }, 6000)
      this.Alert(error?.error.error);
    }
    if (error?.error?.non_field_errors) {
      this.Alert(error?.error?.non_field_errors)
    }

    if (statusCode === 400) {
      if (error.error.show) {
        if (error.error.error) {
          this.Alert(error?.error?.error)
        }
        else if (error.error.message) {
          this.Alert(error.error.message)
        }else if (error.statusText) {
          this.Alert(error.statusText)
        }
        else {
          this.Alert(error)
        }
      }      
    }    

    return throwError(error);
  }

  public delete(apiUrl: string): Observable<any> {
    return this.http.delete<any>(`${API_USERS_URL}/${apiUrl}`, {
      headers: this.authorization(),
      observe: 'response' // Add observe option to get the full HTTP response
    })
      .pipe(
        map((response: HttpResponse<any>) => this.handleResponse(response)), // Handle the response
        catchError((error: HttpErrorResponse) => this.handleError(error)) // Handle errors
      );
  }

  public deleteWithBody(apiUrl: string, body: any): Observable<any> {
    return this.http.delete<any>(`${API_USERS_URL}/${apiUrl}`, {
      headers: this.authorization(),
      body,
      observe: 'response' // Add observe option to get the full HTTP response
    })
      .pipe(
        map((response: HttpResponse<any>) => this.handleResponse(response)), // Handle the response
        catchError((error: HttpErrorResponse) => this.handleError(error)) // Handle errors
      );
  }

  public get(apiUrl: string): Observable<any> {
    return this.http.get<any>(`${API_USERS_URL}/${apiUrl}`, {
      headers: this.authorization(),
      observe: 'response' // Add observe option to get the full HTTP response
    })
      .pipe(
        map((response: HttpResponse<any>) => this.handleResponse(response)), // Handle the response
        catchError((error: HttpErrorResponse) => this.handleError(error)) // Handle errors
      );
  }

  public getExcel(apiUrl: string,responseType:string = ''): Observable<ArrayBuffer> {
    return this.http.get(`${API_USERS_URL}/${apiUrl}`, {
      headers: this.authorization(),
      observe: 'response', 
      responseType: 'arraybuffer' 
    })
    .pipe(
      map((response: HttpResponse<ArrayBuffer>) => this.handleArrayBufferResponse(response)), 
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  public postExcel(apiUrl: string, body: any): Observable<Blob> {
    return this.http.post(`${API_USERS_URL}/${apiUrl}`, body, {
        headers: this.authorization(),
        observe: 'response',
        responseType: 'blob' // Correctly specify that you're expecting a Blob response
      })
      .pipe(
        map((response: HttpResponse<Blob>) => this.handleBlobResponse(response)), // Ensure you're handling Blob response
        catchError(this.handleError.bind(this)) // Handle errors correctly
      );
  }

  private handleBlobResponse(response: HttpResponse<Blob>): Blob {
    if (response.body === null) {
      throw new Error('Response body is null.');
    }
    return response.body;
  }
  
  
  
  private handleArrayBufferResponse(response: HttpResponse<ArrayBuffer>): ArrayBuffer {
    if (response.body === null) {
      throw new Error('Response body is null.');
    }
    return response.body;
  }

  public getCustomUrl(apiUrl: string): Observable<any> {
    return this.http.get<any>(`${apiUrl}`, {
      headers: this.authorization(),
      observe: 'response' // Add observe option to get the full HTTP response
    })
      .pipe(
        map((response: HttpResponse<any>) => this.handleResponse(response)), // Handle the response
        catchError((error: HttpErrorResponse) => this.handleError(error)) // Handle errors
      );
  }

  public postCustomUrl(apiUrl: string, body: any): Observable<any> {
    return this.http.post<any>(`${apiUrl}`, body, {
      headers:{
        'Content-Type': 'application/octet-stream',
        'x-amz-acl': 'public-read' // Include ACL header
      },
      observe: 'response' // Add observe option to get the full HTTP response
    })
      .pipe(
        map((response: HttpResponse<any>) => this.handleResponse(response)), // Handle the response
        catchError((error: HttpErrorResponse) => this.handleError(error)) // Handle errors
      );
  }

  public putCustomUrl(apiUrl: string, body: any): Observable<any> {
    return this.http.put<any>(`${apiUrl}`, body, {
      observe: 'response'
    })
      .pipe(
        map((response: HttpResponse<any>) => this.handleResponse(response)), // Handle the response
        catchError((error: HttpErrorResponse) => this.handleError(error)) // Handle errors
      );
  }

  public post(apiUrl: string, body: any,excludeDomain:boolean = false): Observable<any> {
    let url:any
    if(excludeDomain){
      url = `${apiUrl}`
    }else{
      url = `${API_USERS_URL}/${apiUrl}`
    }
    return this.http.post<any>(url, body, {
      headers: this.authorization(),
      observe: 'response' // Add observe option to get the full HTTP response
    })
      .pipe(
        map((response: HttpResponse<any>) => this.handleResponse(response)), // Handle the response
        catchError((error: HttpErrorResponse) => this.handleError(error)) // Handle errors
      );
  }

  public put(apiUrl: string, body: any, multipart: boolean = false): Observable<any> {
    return this.http.put<any>(`${API_USERS_URL}/${apiUrl}`, body, {
      headers: this.authorization(multipart),
      observe: 'response' // Add observe option to get the full HTTP response
    })
      .pipe(
        map((response: HttpResponse<any>) => this.handleResponse(response)), // Handle the response
        catchError((error: HttpErrorResponse) => this.handleError(error)) // Handle errors
      );
  }

  public patch(apiUrl: string, body: any, multipart: boolean = false): Observable<any> {
    return this.http.patch<any>(`${API_USERS_URL}/${apiUrl}`, body, {
      headers: this.authorization(multipart),
      observe: 'response' // Add observe option to get the full HTTP response
    })
      .pipe(
        map((response: HttpResponse<any>) => this.handleResponse(response)), // Handle the response
        catchError((error: HttpErrorResponse) => this.handleError(error)) // Handle errors
      );
  }
  

  org() {
    let orgId = localStorage.getItem('Org_Id');
    if (!orgId) {
      let organization: any = localStorage.getItem('organization');
      organization = JSON.parse(organization);
      if (organization) {
        orgId = organization.id
        if (orgId) {
          localStorage.setItem('Org_Id', orgId);
        }
      }      
    }
    return orgId;
  }
}
