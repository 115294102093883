import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2'

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  constructor() {}

  showAlert(options: SweetAlertOptions): Promise<any> {
    return Swal.fire(options);
  }
}
