<form [formGroup]="form">
  <div class="mb-5 mb-xxl-5">
    <!--begin::Header-->
    <div class="card-header bg-light">
      <div class="card-title">
        <button 
        class="btn btn-sm rounded" 
        [ngClass]="{'btn-primary': !selectedButtons.includes('Attachments'), 'btn-secondary': selectedButtons.includes('Attachments')}"
        (click)="toggleAttachments('Attachments', 'Attachments')">Attachments</button>
      
      <button 
        class="btn btn-sm rounded" 
        [ngClass]="{'btn-primary': !selectedButtons.includes('Additional Details'), 'btn-secondary': selectedButtons.includes('Additional Details')}"
        (click)="toggleAttachments('Additional Details', 'Additional Details')">Additional Details</button>
      
      <button 
        class="btn btn-sm rounded" 
        [ngClass]="{'btn-primary': !selectedButtons.includes('Comment'), 'btn-secondary': selectedButtons.includes('Comment')}"
        (click)="toggleAttachments('Comment', 'Comment')">Comment</button>
      
      <button 
        class="btn btn-sm rounded" 
        [ngClass]="{'btn-primary': !selectedButtons.includes('Attach Signature'), 'btn-secondary': selectedButtons.includes('Attach Signature')}"
        (click)="toggleAttachments('Attach Signature', 'Attach Signature')">Attach Signature</button>    
      </div>
    </div>
    
    <div class="row g-5 g-xl-10 pt-4 ps-5">
      <!-- Attachments Section -->
      <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5">
        <div *ngIf="showAttachments" class="card card-flush bgi-no-repeat bgi-size-contain border border-secondary bgi-position-x-end h-md-1 h-200px">
          <div class="card-header bg-light">
              <div class="card-title">
              <h4>Attachments</h4> </div>
          </div>
          <div class="drop-zone h-200px d-flex align-items-center justify-content-center" (click)="fileInput.click()">
            <h3>Click here to upload</h3>
            <input type="file" name="attachments" enctype="multipart/form-data" #fileInput hidden
              (change)="handleAttachmentsUpload($event)">
          </div>
        </div>
    
        <!-- Additional Details Section -->
        <div *ngIf="showAdditionalDetails" class="card card-flush h-md-1 border border-secondary h-200px">
          <div class="card-header bg-light">
            <div class="card-title">
              <h4>Terms & Conditions</h4> 
            </div>
          </div>
          <textarea class="h-200px form-control"></textarea>
        </div>
      </div>

      <!-- Comment and Signature Section -->
      <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5">
        <div *ngIf="showComment" class="card card-flush h-md-1 border border-secondary h-200px">
          <div class="card-header bg-light">
            <div class="card-title">
              <h4>Comment</h4> 
            </div>
          </div>
          <textarea class="h-200px form-control"></textarea>
        </div>

        <!-- Signature Upload -->
        <div *ngIf="showSignature" class="card card-flush h-md-1 border border-secondary h-200px">
          <div class="card-header bg-light">
            <div class="card-title">
              <h4>Manage Signature</h4> 
            </div>
          </div>
          <div class="drop-zone h-200px d-flex align-items-center justify-content-center text-center" (click)="signatureInput.click()">
            <h3>Click here to upload</h3>
            <input type="file" name="signature" enctype="multipart/form-data" #signatureInput hidden
              (change)="handleSignatureUpload($event)">
          </div>
        </div>
      </div>
    </div>
  </div>
</form>