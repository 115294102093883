import { Component, Input, OnInit } from '@angular/core';
import { OrgData } from '../orgData';

@Component({
  selector: 'app-org-chart',
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart.component.scss'],
})
export class OrgChartComponent  implements OnInit {
  @Input() data: OrgData;
	@Input() hasParent = false;
	hideChild = false;
	
	toggleShowChild(){
		this.hideChild = !this.hideChild;
	}

  constructor() { }

  ngOnInit() {}

}
