import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OrganizationService } from '../../../../../modules/common/services/organization.service';
import { Router } from '@angular/router';
import { RoleService } from 'src/app/modules/common/services/role.service';
import { RoutingUtil } from 'src/app/_metronic/kt/_utils/routingUtils';
import { Observable } from 'rxjs';
import { ConnectBaseService } from 'src/app/_metronic/kt/_utils/base.service';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {
  org: any;
  userData: any;
  isOrgCreate: any;
  artiFacts: any;
  orgSubscribe: any;
  selectedItem: any
  roleId: any;
  route: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private organizationService: OrganizationService,
    private roleService: RoleService,
    private routingutil: RoutingUtil,
    private baseService:ConnectBaseService
  ) {
    this.org = Number(localStorage.getItem('Org_Id'));
    this.orgSubscribe = this.organizationService.org$.subscribe(() => {
      if (this.org != null) {
        this.isOrgCreate = false;
      }
      this.getArtifacts();
    });
    this.route = this.routingutil.route$.subscribe(() => {
      this.selectedItem = this.routingutil.getItem();
    })

  }

  ngOnInit(): void {
    this.userData = this.organizationService.getAuthFromLocalStorage();
    this.isOrgCreate = this.userData?.is_org_create;
    this.selectedItem = this.routingutil.getItem();
  }

  getArtifacts() {
    let organization: any = localStorage.getItem('organization');
    let module: any = localStorage.getItem('module');
    module = JSON.parse(module);
    organization = JSON.parse(organization);
    this.roleId = organization.user_role;
    if (module) {
      this.organizationService.setUserAccess(this.routingutil.getArtifactAndParent(module.list, module.childName));
    }
    if (this.roleId == "ADMIN") {
      this.roleService.getArtifacts().subscribe((data) => {
        this.artiFacts = data.artifacts;
        this.organizationService.setArtifactsValue(this.artiFacts);
        this.baseService.setArtiFactsItems(this.artiFacts)
        this.routingutil.filterArrayBySlug();
        this.cdr.detectChanges();
      });
    } else if (this.roleId == null) {

      this.router.navigateByUrl(`/error/404`)
    } else {
      this.roleService.getRoleRetrieve(this.roleId).subscribe((data) => {
        this.artiFacts = data.data.artifacts;
        this.organizationService.setArtifactsValue(this.artiFacts);
        this.baseService.setArtiFactsItems(this.artiFacts)
        this.routingutil.filterArrayBySlug();
        this.cdr.detectChanges();
      });

    }
  }

  isModuleAccess(item: any) {
    return item.is_read || item.is_delete || item.is_update || item.is_create || this.roleId == "ADMIN";
  }

  getOrglist() {
    this.organizationService.getOrganizations(true).subscribe((data) => {
      if (localStorage.getItem('Org_Id') == null) {
        this.getZeroIndexId(data.results)
      } else {
        this.org = Number(localStorage.getItem('Org_Id'));
      }
      this.cdr.detectChanges();
    });
  }

  goToOrg() {
    this.trigger();
    this.org = Number(localStorage.getItem('Org_Id'));
    this.router.navigateByUrl(`/organization/${this.org}/overview`)
  }

  trigger() {
    this.organizationService.triggerEventOrg();
  }

  getZeroIndexId(arr: any[]): any {
    if (arr.length > 0) {
      return this.org = arr[0].id;
    } else {
      return null;
    }
  }

  ngOnDestroy(): void {
    this.orgSubscribe.unsubscribe();
    if (this.route) {
      this.route.unsubscribe();
    }
  }

  redirect(name:any){
    this.router.navigateByUrl(`/${name}`)
  }

  navigate(parentItem: any, child?: any) {
    this.routingutil.navigateToParentAndChild(parentItem, child)
  }
}
