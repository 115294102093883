<!--begin::Toolbar container-->
<nav class="navbar navbar-expand-lg navbar-light nav-flex">
<div id="kt_app_toolbar_container" class="app-container d-block d-lg-flex container-fluid" [ngClass]="appToolbarContainerCSSClass">
  <ng-container *ngIf="showPageTitle() && home">
    <app-page-title [appPageTitleDirection]="appPageTitleDirection" [appPageTitleBreadcrumb]="appPageTitleBreadcrumb"
      [appPageTitleDescription]="appPageTitleDescription" class="page-title d-flex flex-wrap me-3"
      [ngClass]="{'flex-column justify-content-center': appPageTitleDirection === 'column', 'align-items-center': appPageTitleDirection !== 'column', appPageTitleCSSClass}">
    </app-page-title>
  </ng-container>

  <ng-container *ngIf="appToolbarLayout === 'accounting' || accounting">
    <app-accounting class="d-flex align-items-center me-5"></app-accounting>
  </ng-container>
 
 <ng-container *ngIf="appToolbarLayout === 'classic'">
  <app-classic class="d-flex align-items-center gap-2 gap-lg-3 mt-5"></app-classic>
</ng-container>
  <ng-container *ngIf="appToolbarLayout === 'extended'">
    <app-extended class="d-flex align-items-center flex-shrink-0 me-5"></app-extended>
  </ng-container>
  <ng-container *ngIf="appToolbarLayout === 'reports'">
    <app-reports class="d-flex align-items-center overflow-auto" [appPageTitleDisplay]="appPageTitleDisplay">
    </app-reports>
  </ng-container>
  <ng-container *ngIf="appToolbarLayout === 'saas'">
    <app-saas class="d-flex align-items-center gap-2" [appPageTitleDisplay]="appPageTitleDisplay"></app-saas>
  </ng-container>
</div>
<!--end::Toolbar container-->
</nav>