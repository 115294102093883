import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConnectBaseService } from 'src/app/_metronic/kt/_utils/base.service';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService extends ConnectBaseService {
  private versionCheckUrl = 'app-version'; 

  checkVersion(): Promise<any> {
    return this.get(`${this.versionCheckUrl}/?organization_id=14`).toPromise();
  }
}
