import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityComponent } from './entity/entity.component';
import { OrgChartComponent } from './org-chart/org-chart.component';



@NgModule({
  declarations: [
    EntityComponent,
    OrgChartComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    OrgChartComponent
  ]
})
export class OrgChartModule { }
