<!-- begin::Header -->
<div class="card-header border-0">
  <h4 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder text-dark">Tasks Overview</span>
    <span class="text-muted mt-1 fw-bold fs-7">Pending 10 tasks</span>
  </h4> 

  <div class="card-toolbar">
    <!-- begin::Menu -->
    <button
      type="button"
      class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <span
        [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </button>
    <app-dropdown-menu1></app-dropdown-menu1>
    <!-- end::Menu -->
  </div>
</div>
<!-- end::Header -->

<!-- begin::Body -->
<div class="card-body pt-5">
  <!-- begin::Item -->
  <div class="d-flex align-items-center mb-7">
    <!-- begin::Symbol -->
    <div class="symbol symbol-35px me-5">
      <span class="symbol-label bg-light-success">
        <span
          [inlineSVG]="'./assets/media/icons/duotune/abstract/abs027.svg'"
          class="svg-icon svg-icon-2x svg-icon-success"
        ></span>
      </span>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Text -->
    <div class="d-flex flex-column">
      <a href="#" class="text-dark text-hover-primary fs-6 fw-bolder">
        Project Briefing
      </a>
      <span class="text-muted fw-bold">Project Manager</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center mb-7">
    <!-- begin::Symbol -->
    <div class="symbol symbol-35px me-5">
      <span class="symbol-label bg-light-warning">
        <span
          [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
          class="svg-icon svg-icon-2x svg-icon-warning"
        ></span>
      </span>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Text -->
    <div class="d-flex flex-column">
      <a href="#" class="text-dark text-hover-primary fs-6 fw-bolder">
        Concept Design
      </a>
      <span class="text-muted fw-bold">Art Director</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center mb-7">
    <!-- begin::Symbol -->
    <div class="symbol symbol-35px me-5">
      <span class="symbol-label bg-light-primary">
        <span
          [inlineSVG]="'./assets/media/icons/duotune/general/gen022.svg'"
          class="svg-icon svg-icon-2x svg-icon-primary"
        ></span>
      </span>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Text -->
    <div class="d-flex flex-column">
      <a href="#" class="text-dark text-hover-primary fs-6 fw-bolder">
        Functional Logics
      </a>
      <span class="text-muted fw-bold">Lead Developer</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center mb-7">
    <!-- begin::Symbol -->
    <div class="symbol symbol-35px me-5">
      <span class="symbol-label bg-light-danger">
        <span
          [inlineSVG]="'./assets/media/icons/duotune/coding/cod008.svg'"
          class="svg-icon svg-icon-2x svg-icon-danger"
        ></span>
      </span>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Text -->
    <div class="d-flex flex-column">
      <a href="#" class="text-dark text-hover-primary fs-6 fw-bolder">
        Development
      </a>
      <span class="text-muted fw-bold">DevOps</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center">
    <!-- begin::Symbol -->
    <div class="symbol symbol-35px me-5">
      <span class="symbol-label bg-light-info">
        <span
          [inlineSVG]="'./assets/media/icons/duotune/general/gen049.svg'"
          class="svg-icon svg-icon-2x svg-icon-info"
        ></span>
      </span>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Text -->
    <div class="d-flex flex-column">
      <a href="#" class="text-dark text-hover-primary fs-6 fw-bolder">
        Testing
      </a>
      <span class="text-muted fw-bold">QA Managers</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
</div>
<!-- end::Body -->
