<div class="card-body d-flex flex-column p-0">
  <div class="d-flex flex-stack flex-grow-1 card-p">
    <div class="d-flex flex-column me-2">
      <a href="#" class="text-dark text-hover-primary fw-bolder fs-3">
        {{ title }}
      </a>

      <span class="text-muted fw-bold mt-1">
        <ng-content select="description"></ng-content>
      </span>
    </div>

    <span class="symbol symbol-35px">
      <span
        class="symbol-label fs-5 fw-bolder"
        [ngClass]="'bg-light-' + color"
        [ngClass]="'text-' + color"
      >
        {{ change }}
      </span>
    </span>
  </div>

  <div
    #chartRef
    class="statistics-widget-3-chart card-rounded-bottom"
    [style.height]="'150px'"
  >
    <apx-chart
      type="area"
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [dataLabels]="chartOptions.dataLabels"
      [stroke]="chartOptions.stroke"
      [legend]="chartOptions.legend"
      [fill]="chartOptions.fill"
      [states]="chartOptions.states"
      [tooltip]="chartOptions.tooltip"
      [colors]="chartOptions.colors"
      [markers]="chartOptions.markers"
      [plotOptions]="chartOptions.plotOptions"
    ></apx-chart>
  </div>
</div>
