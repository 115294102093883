<!--begin::Logo image-->
<a routerLink="/dashboard">
  <ng-container *ngIf="currentLayoutType === 'dark-sidebar'">
    <img alt="Logo" src="./assets/media/logos/logo.png" class="h-95px app-sidebar-logo-default" />
  </ng-container>
  <ng-container *ngIf="currentLayoutType !== 'dark-sidebar'">
    <img alt="Logo" src="./assets/media/logos/color-logo-with-background.png" class="h-95px app-sidebar-logo-default theme-light-show" />
    <img alt="Logo" src="./assets/media/logos/logo.png"
      class="h-95px app-sidebar-logo-default theme-dark-show" />
  </ng-container>

  <img alt="Logo" src="./assets/media/logos/Fevicon.png" class="h-20px app-sidebar-logo-minimize" />
</a>
<!--end::Logo image-->


<ng-container *ngIf="toggleEnabled">
  <!--begin::Sidebar toggle-->
  <div id="kt_app_sidebar_toggle"
    class="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
    [ngClass]="toggleButtonClass" data-kt-toggle="true" [attr.data-kt-toggle-state]="toggleState"
    data-kt-toggle-target="body" [attr.data-kt-toggle-name]="toggleAttr">
    <span [inlineSVG]="'./assets/media/icons/duotune/arrows/arr079.svg'" class="svg-icon svg-icon-2 rotate-180"></span>
  </div>
  <!--end::Sidebar toggle-->
</ng-container>
