<!-- begin::Header -->
<div class="card-header border-0">
  <h4 class="card-title fw-bolder text-dark">Authors</h4> 
  <div class="card-toolbar">
    <!-- begin::Menu -->
    <button
      type="button"
      class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <span
        [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </button>
    <app-dropdown-menu1></app-dropdown-menu1>
    <!-- end::Menu -->
  </div>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body pt-2">
  <!-- begin::Item -->
  <div class="d-flex align-items-center mb-7">
    <!-- begin::Avatar -->
    <div class="symbol symbol-35px me-5">
      <img src="./assets/media/avatars/300-6.jpg" class="" alt="" />
    </div>
    <!-- end::Avatar -->
    <!-- begin::Text -->
    <div class="flex-grow-1">
      <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">
        Emma Smith
      </a>
      <span class="text-muted d-block fw-bold">Project Manager</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center mb-7">
    <!-- begin::Avatar -->
    <div class="symbol symbol-35px me-5">
      <img src="./assets/media/avatars/300-5.jpg" class="" alt="" />
    </div>
    <!-- end::Avatar -->
    <!-- begin::Text -->
    <div class="flex-grow-1">
      <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">
        Sean Bean
      </a>
      <span class="text-muted d-block fw-bold">PHP, SQLite, Artisan CLI</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center mb-7">
    <!-- begin::Avatar -->
    <div class="symbol symbol-35px me-5">
      <img src="./assets/media/avatars/300-11.jpg" class="" alt="" />
    </div>
    <!-- end::Avatar -->
    <!-- begin::Text -->
    <div class="flex-grow-1">
      <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">
        Brian Cox
      </a>
      <span class="text-muted d-block fw-bold">PHP, SQLite, Artisan CLI</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center mb-7">
    <!-- begin::Avatar -->
    <div class="symbol symbol-35px me-5">
      <img src="./assets/media/avatars/300-9.jpg" class="" alt="" />
    </div>
    <!-- end::Avatar -->
    <!-- begin::Text -->
    <div class="flex-grow-1">
      <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">
        Francis Mitcham
      </a>
      <span class="text-muted d-block fw-bold">PHP, SQLite, Artisan CLI</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-center">
    <!-- begin::Avatar -->
    <div class="symbol symbol-35px me-5">
      <img src="./assets/media/avatars/300-23.jpg" class="" alt="" />
    </div>
    <!-- end::Avatar -->
    <!-- begin::Text -->
    <div class="flex-grow-1">
      <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">
        Dan Wilson
      </a>
      <span class="text-muted d-block fw-bold">PHP, SQLite, Artisan CLI</span>
    </div>
    <!-- end::Text -->
  </div>
  <!-- end::Item -->
</div>
<!-- end::Body -->
