import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ModalComponent, ModalConfig, ModalsModule } from 'src/app/_metronic/partials';
import { OrganizationService } from 'src/app/modules/common/services/organization.service';
import { SetUpService } from 'src/app/modules/common/services/set-up.service';
import { en_US_rawMaterials } from 'src/app/_metronic/kt/_utils/en_us';
import { Select2Module } from 'ng-select2-component';
import { FormControlErrorComponent } from '../_formControlErrorComponent';
import { SharedModule } from 'src/app/modules/common/module/shared.module';
import { APIService } from 'src/app/modules/common/services/api.service';
import { apiUrl } from '../../_utils/api-url.enum';

@Component({
  selector: 'app-quotation-detail-location',
  templateUrl: './quotation-detail-location.component.html',
  styleUrls: ['./quotation-detail-location.component.scss'],
  standalone: true,
  imports: [CommonModule, ModalsModule ,FormControlErrorComponent, FormsModule, ReactiveFormsModule, Select2Module]
})
export class QuotationDetailLocationComponent  implements OnInit {

  rawMaterial: any[] = en_US_rawMaterials
  overlay: any;
  paymentterms: any;
  terms: any[];
  countries: any;
  newCountry: any[];
  orgWorkAddresses: any;
  selectFormatOrgWorkAddresses: any[];
  modalConfig: ModalConfig = {
    modalTitle: 'Billing Location',
    dismissButtonLabel: 'Submit',
    closeButtonLabel: 'Cancel',
    onDismiss: () => this.submit(),
  };
  @ViewChild('modal') private modalComponent: ModalComponent;
  currentOrgId = Number(localStorage.getItem('Org_Id'));
  documentDetailForm: FormGroup;
  fullAddress: string | null;
  newDocumentSeries: any[];
  documentSeries: any;

  billingLocationForm: FormGroup;

  constructor(
    private cdr: ChangeDetectorRef,
    private organizationService: OrganizationService,
    private setupservice: SetUpService,
    private fb: FormBuilder,
    private apiService: APIService,

  ) { }

  ngOnInit() {
    this.initForm();
    this.getPaymentTerm();
    this.getCountries();
    this.getOrgWorkAddress();
    this.getDocumentSeries();
  }

  initForm() {
    this.documentDetailForm = this.fb.group({
      billing_address: ['', [Validators.required]],
      document_series_number: ['', [Validators.required]],
      bidding_end_date: ['', [Validators.required]],
      delivery_date: ['', [Validators.required]],
      bidding_start_date: ['', [Validators.required]],
      document_series: ['']
    })
  }

  async openModal() {
    this.documentDetailForm.reset();
    await this.modalComponent.open();
  }

  getPaymentTerm() {
    this.setupservice.getPaymentTerm(undefined, undefined, true).subscribe({
      next: (data) => {
        this.paymentterms = data.results;
        this.terms = this.organizationService.convertToValueLabelArray(this.paymentterms, 'id', 'name');
        this.cdr.detectChanges();
      },
    })
  }

  getCountries() {
    this.organizationService.getCountries(true).subscribe({
      next:(data)=> {
        this.countries = data;
        this.newCountry = this.organizationService.convertToValueLabelArray(this.countries.results,'id','name');
        this.cdr.detectChanges();
      },
    });
  }

  getDocumentSeries() {
    this.apiService.listItem(apiUrl.production_document_series, `all_result=true`).subscribe({
      next:(data)=> {
        this.documentSeries = data;
        this.newDocumentSeries = this.organizationService.convertToValueLabelArray(this.documentSeries.results,'id','name');
        this.cdr.detectChanges();
      },
    });
  }

  getOrgWorkAddress() {
    this.organizationService.getWorkAddress(true).subscribe((data) => {
      this.orgWorkAddresses = data.results;
      this.selectFormatOrgWorkAddresses = this.organizationService.convertToValueLabelArray(
        this.orgWorkAddresses, 'id', 'title'
      );
      this.cdr.detectChanges();
    })
  }

  getFullWorkAddress(id: any) {
    const workAddress = this.orgWorkAddresses?.find((workAddress: any) => workAddress.id === id);
    if (workAddress) {
      const { address_line_1, address_line_2, city, state, country_name, postal_code } = workAddress.address;
      return `${address_line_1 ?? ''} ${address_line_2 ?? ''}, ${city ?? ''}, ${state ?? ''}, ${country_name ?? ''} - ${postal_code}`;
    }
    return null;
  }

  emitFormData() {
    return this.documentDetailForm.value;
  }

  submit() {
    if (this.documentDetailForm.get('billing_address')?.value) {
      try {
        const selectedAddressId = this.documentDetailForm.get('billing_address')?.value;
        this.fullAddress = this.getFullWorkAddress(selectedAddressId);
      } catch (error) {
        console.error(error);
      }
      return true;
    }
    return false
  }

}
