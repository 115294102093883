export enum apiUrl {
    Attendance_excel_report= 'attendance-report/excel',
    appoinment_event_type= 'appointment/event-type',
    appointment_meetings= 'appointment/slot-booking',
    production_setup_store = 'production/store',
    production_setup_routing = 'production/routing',
    production_setup_othercharges = 'production/other-charges',
    production_document_series = 'production/document/series',
    production_bom = 'production/bom',
    production_order = 'production/order',
    product_sku = 'product/sku',
    product_sku_date = 'product/sku/data',
    production_order_cycle = 'production-order-cycle',
    integration_sqlServer = 'attendance/biometric/server',
    integration_downloadBiometricData = 'attendance/biometric/server/download',
    get_available_slots = 'get-available-slots',
    get_purchase_inquiry = 'purchase/inquiry',    
    get_purchase_public_inquiry = 'purchase/public/inquiry',
    get_purchase_public_po = 'purchase/public/order',
    get_purchase_public_po_status = 'purchase/public/order/update-status',
    get_purchase_po = 'purchase/order',
    get_purchase_inward = 'purchase/inward',
    get_public_purchase_quotation = 'purchase/public/quotation',
    get_purchase_quotation = 'purchase/quotation',
    get_pruchase_grn =  'purchase/grn',
    get_sales_tax_class = 'sales/vendor-tax',
    get_public_sales_tax_class = 'sales/public/vendor-tax',
    get_sales_transporters = 'sales/transporter',
    get_sales_inquiry = 'sales/inquiry',
    get_sales_quotation = 'sales/quotation',
    get_sales_order_confirmation = 'sales/order-confirmation',
    get_sales_proforma = 'sales/proforma-invoice',
    get_sales_tax_invoice = 'sales/tax-invoice',
    get_sales_sales_return = 'sales/return',
    get_sales_challan = 'sales/challan',
    get_inventory_stock_movement= 'inventory/stock/movement',
    get_stock_transfer = 'inventory/stock/transfer',
    get_inventory_stock_adjustment = 'inventory/stock/adjustment',
    submit_expense = "submit_expense",
    get_customer_order_payment = 'customer/order-payment',
    get_sales_vendor_invoice_payment = 'sales/vendor-invoice-payment',
    get_sales_payment_method = 'sales/payment-method',
    get_pos_configuration = 'sales/pos/configuration',
    get_receipt_voucher = 'sales/receipt-voucher',
    get_gst_one = 'organization/sales/gst1',
    get_gst_two = 'organization/sales/gst2',
    get_gst_three_b = 'organization/sales/gst3b',
    get_gst_nine = 'organization/sales/gst9',
    get_gst_summary = 'organization/sales/gst-summary',
    get_gst_one_filter = 'organization/sales/gst1/filters',
    get_purchase_tax_invoice = 'purchase/tax-invoice',
    get_purchase_credit_note = 'purchase/credit-note',
    get_purchase_debit_note = 'purchase/debit-note',
    get_sales_vendor = 'sales/vendor',
    get_credit_note = 'sales/credit-note',
    get_debit_note = 'sales/debit-note',
    get_payment_term = `payment-term`,
    get_sales_product = `salesproduct`,
    get_sales_setup_tags = `sales/tags`,
    get_member_bulk_import = `organizations/members/bulk-import`,
    get_ai_assist = `hiring/generate/job/description`,
}