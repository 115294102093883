import { Directive, HostListener, Input } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { APIService } from 'src/app/modules/common/services/api.service';
import { apiUrl } from './api-url.enum';
import { Select2SearchEvent } from 'ng-select2-component';
import { Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { OrganizationService } from 'src/app/modules/common/services/organization.service';

@Directive({
    selector: '[appSearchDD]'
})
export class DropdownDirective {
    @Input() params: string | null = null;
    @Input() componentValue: string | null = null;
    @Input() searchItem: any = 'product';
    private searchSubject: Subject<string> = new Subject();
    eventSearchData: any

    constructor(
        private apiService: APIService,
        private organizationService: OrganizationService,
        private cdr: ChangeDetectorRef,
    ) {
        this.searchSubject.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap(searchTerm => {
                const searchItemConfig = {
                    product: {
                        apiUrl: apiUrl.get_sales_product,
                        transform: (data: any) => this.addLabelAndValue(data.results, 'sku')
                    },
                    sku: {
                        apiUrl: apiUrl.product_sku,
                        transform: (data: any) => this.addSKuLabelAndValue(data, 'sku')
                    },
                    vendor: {
                        apiUrl: apiUrl.get_sales_vendor,
                        transform: (data: any) => this.organizationService.convertToValueLabelArray(data?.results, 'id', 'company_name')
                    },
                    customer: {
                        apiUrl: apiUrl.get_customer,
                        transform: (data: any) => this.organizationService.convertToValueLabelArray(data?.results, 'id', 'company_name')
                    },
                    member: {
                        apiUrl: apiUrl.get_org_member,
                        transform: (data: any) => this.organizationService.convertToValueLabelArray(data?.results, 'id', 'first_name,last_name')
                    }
                }[this.searchItem as 'product' | 'vendor' | 'customer' | 'sku' | 'member'];

                if (searchItemConfig) {
                    return this.apiService.listItem(searchItemConfig.apiUrl, searchTerm);
                }
                return [];
            })
        ).subscribe((data) => {
            const searchItemConfig = {
                product: {
                    apiUrl: apiUrl.get_sales_product,
                    transform: (data: any) => this.addLabelAndValue(data.results, 'sku')
                },
                vendor: {
                    apiUrl: apiUrl.get_sales_vendor,
                    transform: (data: any) => this.organizationService.convertToValueLabelArray(data?.results, 'id', 'company_name',true)
                },
                sku: {
                    apiUrl: apiUrl.product_sku,
                    transform: (data: any) => this.addSKuLabelAndValue(data, 'sku',)
                },
                customer: {
                    apiUrl: apiUrl.get_customer,
                    transform: (data: any) => this.organizationService.convertToValueLabelArray(data?.results, 'id', 'company_name',true)
                },
                member: {
                    apiUrl: apiUrl.get_org_member,
                    transform: (data: any) => this.organizationService.convertToValueLabelArray(data?.results, 'id', 'first_name,last_name',true)
                }
            }[this.searchItem as 'product' | 'vendor' | 'customer' | 'sku' | 'member'];

            if (searchItemConfig) {
                const updatedData = searchItemConfig.transform(data);
                this.eventSearchData.component.filteredData = updatedData;
            }
        });
    }

    @HostListener('search', ['$event'])
    onSearch(event: Select2SearchEvent) {
        const eventSearchData: any = event;
        const searchTerm = eventSearchData.search || '';

        if (searchTerm !== '') {
            let params = `search=${searchTerm}`;
            if (this.params) {
                params += `&${this.params}`;
            }
            this.eventSearchData = event
            this.searchSubject.next(params);
        }
    }


    addLabelAndValue(data: any, key: any) {
        return data?.map((item: any) => ({
            ...item,
            label: `(${item[key]}) ${item?.name}`,
            value: item?.id,
            data: item
        }));
    }

    addSKuLabelAndValue(data: any, key: any) {
        return data?.map((item: any) => ({
          ...item,
          product_variation_groups: this.addVariationLabelAndValue(item.product_variation_groups, 'group_name', 'id'),
          label: `(${item[key]}) ${item?.name}`,
          value: item[key]
        }));
      }
    
      addVariationLabelAndValue(data: any, title: any, id: any) {
        return data?.map((item: any) => ({
          ...item,
          label: item[title],
          value: item[id]
        }));
      }
}
