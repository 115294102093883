import { AbstractControl } from '@angular/forms';

// export class ConfirmPasswordValidator {
  /**
   * Check matching password with confirm password
   * @param control AbstractControl
   */
//   static MatchPassword(control: AbstractControl): void {
//     const password = control.get('password')?.value;
//     const confirmPassword = control.get('cPassword')?.value;

//     if (password !== confirmPassword) {
//       control.get('cPassword')?.setErrors({ ConfirmPassword: true });
//     }
//   }
// }

export class ConfirmPasswordValidator {
  static MatchPassword(control: AbstractControl) {
    const password = control.get('new_password')?.value;
    const confirmPassword = control.get('confirm_password')?.value;
    if (password !== confirmPassword) {
      control.get('confirm_password')?.setErrors({ MatchPassword: true });
      return { MatchPassword: true };
    } else {
      return null;
    }
  }
}

export class RegistrationConfirmPasswordValidator {
  static MatchPassword(control: AbstractControl) {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('cPassword')?.value;
    if (password !== confirmPassword) {
      control.get('cPassword')?.setErrors({ MatchPassword: true });
      return { MatchPassword: true };
    } else {
      return null;
    }
  }
}
