import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrgData } from '../orgData';

@Component({
  selector: 'app-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss'],
})
export class EntityComponent  implements OnInit {
  @Output() toggleChild = new EventEmitter();
	@Input() data: OrgData;
	@Input() hasParent = false;
	@Input() hideChild: any;

	toggleShowChild(){
		this.toggleChild.emit(new Date());
	}

  constructor() { }

  ngOnInit() {}

}
