<!-- begin::Header -->
<div class="card-header border-0">
  <h4 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1">New Arrivals</span>
    <span class="text-muted mt-1 fw-bold fs-7">Over 500 new products</span>
  </h4> 
  <div class="card-toolbar">
    <a href="#" class="btn btn-sm btn-light-primary">
      <span
        [inlineSVG]="'./assets/media/icons/duotune/arrows/arr075.svg'"
        class="svg-icon svg-icon-3"
      ></span>
      New Member
    </a>
  </div>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body py-3">
  <!-- begin::Table container -->
  <div class="table-responsive">
    <!-- begin::Table -->
    <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 gs-0 gy-2">
      <!-- begin::Table head -->
      <thead>
        <tr class="fw-bolder text-muted bg-light">
          <th class="ps-4 min-w-325px rounded-start">Product</th>
          <th class="min-w-125px">Price</th>
          <th class="min-w-125px">Deposit</th>
          <th class="min-w-200px">Agent</th>
          <th class="min-w-150px">Status</th>
          <th class="min-w-200px text-end rounded-end"></th>
        </tr>
      </thead>
      <!-- end::Table head -->
      <!-- begin::Table body -->
      <tbody>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <div class="symbol symbol-35px me-5">
                <img
                  src="./assets/media/stock/600x400/img-26.jpg"
                  class=""
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-start flex-column">
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Sant Extreanet Solution
                </a>
                <span class="text-muted fw-bold text-muted d-block fs-7">
                  HTML, JS, ReactJS
                </span>
              </div>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              $2,790
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7">Paid</span>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              $520
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7"
              >Rejected</span
            >
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              Bradly Beal
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7"
              >Insurance</span
            >
          </td>
          <td>
            <span class="badge badge-light-primary fs-7 fw-bold">Approved</span>
          </td>
          <td class="text-end">
            <a
              href="#"
              class="
                btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                me-1
              "
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/general/gen019.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
            <a
              href="#"
              class="
                btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                me-1
              "
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
            <a
              href="#"
              class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <div class="symbol symbol-35px me-5">
                <img
                  src="./assets/media/stock/600x400/img-3.jpg"
                  class=""
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-start flex-column">
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Telegram Development
                </a>
                <span class="text-muted fw-bold text-muted d-block fs-7">
                  C#, ASP.NET, MS SQL
                </span>
              </div>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              $4,790
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7">Paid</span>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              $240
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7"
              >Rejected</span
            >
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              Chris Thompson
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7"
              >NBA Player</span
            >
          </td>
          <td>
            <span class="badge badge-light-danger fs-7 fw-bold"
              >In Progress</span
            >
          </td>
          <td class="text-end">
            <a
              href="#"
              class="
                btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                me-1
              "
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/general/gen019.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
            <a
              href="#"
              class="
                btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                me-1
              "
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
            <a
              href="#"
              class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <div class="symbol symbol-35px me-5">
                <img
                  src="./assets/media/stock/600x400/img-9.jpg"
                  class=""
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-start flex-column">
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Payroll Application
                </a>
                <span class="text-muted fw-bold text-muted d-block fs-7">
                  PHP, Laravel, VueJS
                </span>
              </div>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              $4,390
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7">Paid</span>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              $593
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7"
              >Rejected</span
            >
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              Zoey McGee
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7"
              >Ruby Developer</span
            >
          </td>
          <td>
            <span class="badge badge-light-success fs-7 fw-bold">Success</span>
          </td>
          <td class="text-end">
            <a
              href="#"
              class="
                btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                me-1
              "
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/general/gen019.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
            <a
              href="#"
              class="
                btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                me-1
              "
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
            <a
              href="#"
              class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <div class="symbol symbol-35px me-5">
                <img
                  src="./assets/media/stock/600x400/img-18.jpg"
                  class=""
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-start flex-column">
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  HR Management System
                </a>
                <span class="text-muted fw-bold text-muted d-block fs-7">
                  Python, PostgreSQL, ReactJS
                </span>
              </div>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              $7,990
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7">Paid</span>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              $980
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7"
              >Rejected</span
            >
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              Brandon Ingram
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7"
              >Insurance</span
            >
          </td>
          <td>
            <span class="badge badge-light-info fs-7 fw-bold">Rejected</span>
          </td>
          <td class="text-end">
            <a
              href="#"
              class="
                btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                me-1
              "
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/general/gen019.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
            <a
              href="#"
              class="
                btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                me-1
              "
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
            <a
              href="#"
              class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <div class="symbol symbol-35px me-5">
                <img
                  src="./assets/media/stock/600x400/img-8.jpg"
                  class=""
                  alt=""
                />
              </div>
              <div class="d-flex justify-content-start flex-column">
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Telegram Mobile
                </a>
                <span class="text-muted fw-bold text-muted d-block fs-7">
                  HTML, JS, ReactJS
                </span>
              </div>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              $5,790
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7">Paid</span>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              $750
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7"
              >Rejected</span
            >
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
            >
              Natali Trump
            </a>
            <span class="text-muted fw-bold text-muted d-block fs-7"
              >Insurance</span
            >
          </td>
          <td>
            <span class="badge badge-light-warning fs-7 fw-bold">Approved</span>
          </td>
          <td class="text-end">
            <a
              href="#"
              class="
                btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                me-1
              "
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/general/gen019.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
            <a
              href="#"
              class="
                btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                me-1
              "
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/art/art005.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
            <a
              href="#"
              class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/general/gen027.svg'"
                class="svg-icon svg-icon-3"
              ></span>
            </a>
          </td>
        </tr>
      </tbody>
      <!-- end::Table body -->
    </table>
    <!-- end::Table -->
  </div>
  <!-- end::Table container -->
</div>
<!-- begin::Body -->
