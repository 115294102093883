import { EventEmitter, Injectable } from "@angular/core";
import { CompactType, GridType, GridsterConfig, GridsterItem, GridsterItemComponentInterface } from 'angular-gridster2';
import { DashboardService } from "./dashboard.service";


export interface DialingGridComponentConfig {
    key: 'Card' | 'Dashboard',
    gridsterItem: GridsterItem
    removable?: boolean
}

@Injectable({
    providedIn: 'root'
})
export class GridsterConfigService {
    Event: EventEmitter<any> = new EventEmitter<any>();
    private latestItems: GridsterItem[] = [];
    static itemsLatest: GridsterItem[] = [];
    static totalRows = 12;
    static totalColumns = 1;
    listWidget: any;
    static widgetList: any


    updateLatestItems(items: any): void {
        GridsterConfigService.itemsLatest = items;
        this.latestItems = items;
    }

    getLatestItems(): GridsterItem[] {
        return this.latestItems;
    }

    defaultGridComponentConfig(listWidget: any, key: any, isDraggable: any) {
        this.listWidget = listWidget;
        GridsterConfigService.widgetList = listWidget;
        
        const matchingItems = listWidget.filter((config: any) => config.name.toLowerCase() === key.toLowerCase());
        
        if (matchingItems.length > 0) {
          return matchingItems.map((item: any) => ({ ...item, position: { ...item.position, dragEnabled: isDraggable, resizeEnabled: isDraggable } }));
        } else {
          return [];
        }
      }
      

    static eventStart(
        item: GridsterItem,
        itemComponent: GridsterItemComponentInterface,
        event: MouseEvent
    ): void {
        console.info('eventStart', item, itemComponent, event);
    }

    static eventStop(
    ): void {
        console.log(GridsterConfigService.itemsLatest,'this.itemsLatest');
        
    }

    static defaultGridsterOptions: GridsterConfig = {
        minCols: GridsterConfigService.totalColumns,
        maxRows: GridsterConfigService.totalRows,
        displayGrid: "none",
        gridType: GridType.Fit,
        compactType: CompactType.None,
        pushItems: true,
        draggable: {
            enabled: true,
            stop: GridsterConfigService.eventStop,
            // start: GridsterConfigService.eventStart,
        },
        resizable: {
            enabled: true
        }
    }

    initializeGridItems(key: string, isDraggable: boolean, array?: any[]): any[] {
        const item: any = this.listWidget.find((config: any) => config.name.toLowerCase() === key.toLowerCase());
        const data: any = array
        const modifiedPosition = data ? { ...data, dragEnabled: isDraggable, resizeEnabled: isDraggable } : [];
        return item ? [{ ...item, position: modifiedPosition }] : [];
    }

    emit(error: any) {
        this.Event.emit(error);
    }

}